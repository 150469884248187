import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBroadsignSettings, saveBroadsignSettings } from '../../../lib/api/broadsign';
import AvailabilitySection from './Sections/AvailabilitySection';
import { PromotionalRates } from './Sections/PromotionalRatesSection/PomotionalRates';
import StandardRatesSection from './Sections/StandardRatesSection';
import { DEFAULT_MEDIA_TYPES_VALUE } from './constants';
import { cloneDeep } from 'lodash';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';
import { Button } from '@zitcha/component-library';

const HeaderDesc = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
  color: #777785;
`;

const OrganisationInStoreScreensSettings = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [rateRulesErrors, setRateRuleErrors] = useState({});
  const [msg, setMsg] = useState(null);
  const [availableScreenTypes, setAvailableScreenTypes] = useState([]);
  const [screenSettingsForm, setScreenSettingsForm] = useState({
    orientation: [],
    media_types: cloneDeep(DEFAULT_MEDIA_TYPES_VALUE),
    max_booking_date: '',
    //This is hard coded for now
    notification_empty_creatives: {
      unit: 'WEEK',
      period: 1,
    },
    standard_rates: { Portrait: 0, Landscape: 0, 'Landscape_&_Portrait': 0 },
    price_rules: [],
  });

  const loadData = async () => {
    try {
      const response = await getBroadsignSettings();
      if (response?.data) {
        setAvailableScreenTypes(response.data?.available_screen_types);
        setScreenSettingsForm({
          ...screenSettingsForm,
          orientation: response.data?.screen_types,
          media_types: response.data?.media_types,
          max_booking_date: response.data?.max_booking_date,
          standard_rates: response.data?.standard_rates,
        });
      }
    } catch (error) {
      /* eslint-disable no-console */
      console.error('setScreenSettingsForm: error loading in-screen settings data', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg(null);
    setFormErrors({});
    setRateRuleErrors({});

    if (isLoading || isSaving) {
      return;
    }

    setIsSaving(true);

    try {
      await saveBroadsignSettings(screenSettingsForm);
      //clear out price rules when the changes are saved
      setScreenSettingsForm({ ...screenSettingsForm, price_rules: [] });
      setMsg({
        type: 'success',
        body: 'Settings have been saved successfully.',
      });
      //reload page
      setIsLoading(true);
      loadData();
    } catch (error) {
      const errors = error?.response?.data?.errors || [];
      setMsg({
        type: 'danger',
        body: 'Could not save the settings. please check settings form for any errors.',
      });
      setFormErrors(errors);
      if (error?.response?.data.error_code === 'dates_overlap') {
        setRateRuleErrors(error?.response?.data);
      }
      console.error('setScreenSettingsForm: error saving in-screen settings data', error);
    } finally {
      setIsSaving(false);
    }
  };

  const customBreadcrumbs = [
    {
      path: '/organisation/channels',
      title: 'Channel settings',
    },
    {
      path: '/organisation/instorescreens-settings',
      title: 'In-store screens settings',
      active: true,
    },
  ];

  return (
    <BaseLayoutWithChildren heading='In store screen settings' customBreadcrumbs={customBreadcrumbs}>
      <PageSection id='in-store-screens-settings'>
        <Form>
          {msg && (
            <Alert className='mt-3' variant={msg.type}>
              {msg.body}
            </Alert>
          )}
          <Row className='mb-3'>
            <Col md={9}>
              <HeaderDesc>Setup and configure your in-store screens.</HeaderDesc>
            </Col>
            <Col md={3}>
              {!isLoading && (
                <Button className='mr-auto float-right' onClick={handleSubmit} size='large' disabled={isSaving}>
                  <span className='small d-flex'>
                    {isSaving && (
                      <Spinner
                        as='span'
                        size='sm'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                        className='mr-2'
                      />
                    )}
                    <span>Save Changes</span>
                  </span>
                </Button>
              )}
            </Col>
          </Row>
          {isLoading && (
            <Row className='text-center d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
            </Row>
          )}
          {!isLoading && (
            <>
              <AvailabilitySection
                values={screenSettingsForm}
                isSaving={isSaving}
                onChange={(value) => {
                  setScreenSettingsForm({ ...screenSettingsForm, ...value });
                }}
                formErrors={formErrors}
              />
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <StandardRatesSection
                values={screenSettingsForm}
                isSaving={isSaving}
                onChange={(value) => {
                  setScreenSettingsForm({ ...screenSettingsForm, ...value });
                }}
                formErrors={formErrors}
              />
              <PromotionalRates
                portraitAvailable={availableScreenTypes.find((e) => e?.key === 'Portrait') ? true : false}
                landscapeAvailable={availableScreenTypes.find((e) => e?.key === 'Landscape') ? true : false}
                bundleAvailable={availableScreenTypes.find((e) => e?.key === 'Landscape_&_Portrait') ? true : false}
                values={screenSettingsForm}
                isSaving={isSaving}
                onChange={(value) => {
                  setScreenSettingsForm({ ...screenSettingsForm, ...value });
                  setFormErrors({ overlap: {} });
                  setMsg(null);
                }}
                formErrors={{ ...formErrors, overlap: rateRulesErrors }}
              />
            </>
          )}
        </Form>
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default OrganisationInStoreScreensSettings;
