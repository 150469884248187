import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { postAsset } from '../../../../lib/api';
import Errors from '../Errors';
import AddFileSquare from './AddFileSquare';
import CallToAction from './CallToAction';
import styles from './filedropzone.module.scss';
import FileSquare from './FileSquare';
import { PLATFORM_BESPOKE_PRODUCT } from '../../../../lib/platforms';
import { unionBy } from 'lodash';

const FileDropzone = ({
  required = false,
  readOnly = false,
  onChange = () => {},
  onDelete = () => {},
  onUploadStart = () => {},
  onUploadEnd = () => {},
  errors = [],
  value = [],
  max,
  ad,
  videoErrors = () => [],
  accept = [],
  maxDuration,
  postRequest = postAsset,
}) => {
  const fileRef = useRef(null);
  const [progress, setProgress] = useState(null);
  const [dragged, setDragged] = useState(false);
  const [internalErrors, setInternalErrors] = useState([]);
  const errs = errors.concat(internalErrors);

  const removeFile = (file) => onDelete(file);

  const uploadFiles = async (fs) => {
    setInternalErrors([]);

    let video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      if (maxDuration && video.duration > maxDuration) {
        setInternalErrors([
          `You're video it too long. It needs to be under ${moment.duration(maxDuration, 'seconds').humanize()}`,
        ]);
      }
    };
    video.src = URL.createObjectURL(fs[0]);

    onUploadStart();

    let fileTotal = value.length;

    if (fileTotal + fs.length > max) {
      setInternalErrors([`Uploaded too many files: max ${max}`]);
      return;
    }
    let fileChange = [...value];

    for (let i = 0; i < fs.length; i++) {
      let file = fs[i];
      let fext = file.name.split('.').pop().toLowerCase();

      if (!accept.some((e) => e === fext)) {
        setInternalErrors([`Invalid file type, only accepted: ${accept.join(', ')}`]);
        break;
      }

      // TODO: Handle error of upload
      let resp;
      try {
        resp = await postRequest(file, {
          onUploadProgress: (p) => setProgress((p.loaded / p.total) * 100),
        });
      } catch (e) {
        if (e.response?.status === 400) {
          setInternalErrors(e.response.data.error);
        } else {
          setInternalErrors('Something bad happened. Please try again later.');
        }

        setProgress(null);
        break;
      }

      const fileJson = resp.data.data;

      if (fileJson.assettype === 'video') {
        const errors = videoErrors(fileJson);
        if (errors.length > 0) {
          setProgress(null);
          setInternalErrors(errors);
          break;
        }
      }
      fileChange.push(fileJson);
      setProgress(null);

      fileTotal += 1;
    }

    onChange(fileChange);
    onUploadEnd();
  };

  let content = '';
  let uniqueAsset = unionBy(value, 'id');
  if (ad?.platform === PLATFORM_BESPOKE_PRODUCT) {
    if (uniqueAsset.length > max) {
      uniqueAsset.length = max;
    }
    if (uniqueAsset.length > 0 && uniqueAsset.length <= max) {
      content = uniqueAsset.map(
        (f) => !f.order_id && <FileSquare key={f.id} file={f} readOnly={readOnly} onDelete={removeFile} />
      );

      if (!readOnly && uniqueAsset.length < max) {
        content.push(<AddFileSquare key={uniqueAsset.length} fileRef={fileRef} />);
      }
    } else if (!readOnly) {
      content = <CallToAction fileRef={fileRef} />;
    }
  } else {
    if (value.length > 0) {
      content = value.map((f) => <FileSquare key={f.id} file={f} readOnly={readOnly} onDelete={removeFile} />);
      if (!readOnly && value.length < max) {
        content.push(<AddFileSquare key={value.length} fileRef={fileRef} />);
      }
    } else if (!readOnly) {
      content = <CallToAction fileRef={fileRef} />;
    }
  }

  return (
    <div>
      <div
        className={`d-flex rounded ${styles.container} ${
          value.length <= 0 ? styles.container_empty : styles.container_grid
        } ${dragged ? styles.dragged : ''}`}
        onDrop={(e) => {
          e.preventDefault();
          uploadFiles(e.dataTransfer.files);
          setDragged(!readOnly && false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDragged(!readOnly && true);
        }}
        onDragLeave={() => setDragged(!readOnly && false)}
      >
        <div className={`d-flex ${styles.container_group}`}>
          <Form.File
            className='d-none'
            ref={fileRef}
            required={required}
            disabled={readOnly}
            accept={accept.map((f) => `.${f}`).join(',')}
            isInvalid={errs.length > 0}
            multiple
            onChange={(e) => uploadFiles(e.target.files)}
            onClick={(e) => (e.target.value = null)}
          />
          {content}
        </div>
      </div>
      <Form.Control.Feedback className={errs.length > 0 ? 'd-block' : ''} type='invalid'>
        <Errors errors={errs} />
      </Form.Control.Feedback>
      {progress !== null && (
        <ProgressBar className='mt-2' variant={progress === 100 ? 'success' : 'primary'} animated now={progress} />
      )}
      <div>
        <small>
          Accepts up to {max} files. <strong>Uploaded:</strong> {uniqueAsset.length}/{max}.{' '}
          <strong>Accepted file extensions:</strong> {accept.join(', ')}.
        </small>
      </div>
    </div>
  );
};

FileDropzone.propTypes = {
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.object),
  max: PropTypes.number.isRequired,
  videoErrors: PropTypes.func,
  accept: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FileDropzone;
