import React from 'react';
import { Box, Typography, Button, Alert } from '@zitcha/component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import MetricPermissions from '../../features/organisation_settings/components/MetricPermissions';
import { FacebookPermissions } from './FacebookPermissions';
import { GooglePermissions } from './GooglePermissions';
import { MetaManagedPartnerAdSettings } from './MetaManagedPartnerAdSettings';
import AdSettings from '../../features/organisation_settings/components/AdSettings';
import { CHANNELS_MANAGEMENT_FACEBOOK, CHANNELS_MANAGEMENT_GOOGLE } from 'lib/permissions';
import { usePermissions } from 'v2/lib/hooks/usePermissions';

export const GlobalSupplierSettings = ({
  control,
  setValue,
  permissions,
  adSettings,
  adTypes,
  organisation,
  hasFacebookMPAEnabled,
  ENABLE_META_SETTINGS_FEATURE,
  isSubmitting,
  onSubmit,
}) => {
  const { hasPermission } = usePermissions();
  const canManageFacebook = hasPermission(CHANNELS_MANAGEMENT_FACEBOOK);
  const canManageGoogle = hasPermission(CHANNELS_MANAGEMENT_GOOGLE);

  return (
    <form onSubmit={onSubmit}>
      <Box display='flex' justifyContent='flex-end' width='100%'>
        <Button type='submit' variant='contained' color='primary' sx={{ px: 5 }} disabled={isSubmitting}>
          Save Changes
        </Button>
      </Box>
      {hasFacebookMPAEnabled && adSettings?.page_handlers?.length === 0 && (
        <Alert severity='warning' icon={<FontAwesomeIcon icon={faExclamationTriangle} />} sx={{ mb: 2 }}>
          Select the default page(s) to display Meta Managed Partner Ads in the settings below.
        </Alert>
      )}

      <Box mb={4}>
        <MetricPermissions isGeneralSettings={true} permissionsData={permissions} />
      </Box>

      <Box display='flex' mb={4}>
        {canManageFacebook && (
          <Box flex={1} mr={2}>
            <FacebookPermissions control={control} adSettings={adSettings} organisation={organisation} />
          </Box>
        )}
        {canManageGoogle && (
          <Box flex={1} ml={2}>
            <GooglePermissions control={control} adSettings={adSettings} organisation={organisation} />
          </Box>
        )}
      </Box>

      {ENABLE_META_SETTINGS_FEATURE && hasFacebookMPAEnabled && (
        <Box mb={4}>
          <MetaManagedPartnerAdSettings control={control} adSettings={adSettings} setValue={setValue} />
        </Box>
      )}

      <Box mb={4}>
        <Typography variant='h6' mb={1}>
          Ad Types
        </Typography>
        <AdSettings adTypes={adTypes} adSettings={adSettings} />
      </Box>

      <Box display='flex' justifyContent='flex-end' width='100%'>
        <Button type='submit' variant='contained' color='primary' sx={{ px: 5 }} disabled={isSubmitting}>
          Save Changes
        </Button>
      </Box>
    </form>
  );
};
