import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from './Chrome';
import { Organisations } from './Organisations';
import {
  LogoutIcon,
  CloseIcon,
  Box,
  Menu,
  SwitchAccountIcon,
  Typography,
  IconButton,
  Button,
} from '@zitcha/component-library';
import { logout } from 'lib/api';
import { useMutation } from '@tanstack/react-query';

const UserName = ({ networkBrandingContrastText }: { networkBrandingContrastText: string }) => {
  const { user } = useContext(NavigationContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSettled: () => {
      navigate('/logout');
    },
  });
  const handleLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <>
      <Box display='flex'>
        <Box
          sx={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='body1'>
            {user?.first_name} {user?.last_name}
          </Typography>
          <Typography variant='caption'>{user?.active_organisation?.name}</Typography>
        </Box>
        <IconButton
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            color: networkBrandingContrastText,
            marginLeft: '8px',
          }}
          size='medium'
          onClick={handleClick}
        >
          <SwitchAccountIcon />
        </IconButton>
      </Box>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        sx={{
          '.MuiMenu-list': {
            backgroundColor: '#F4F6F8',
            minWidth: '21rem',
            width: '100%',
            padding: '1em',
          },
        }}
      >
        <Box>
          <Box
            display='flex'
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              aria-label='close'
              onClick={handleClose}
              size='medium'
              sx={{
                color: 'grey',
                marginBottom: '1rem',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <Typography variant='caption'>Viewing as {user?.active_organisation?.name}</Typography>
            <Typography variant='h6'>Hi,{user?.first_name}!</Typography>
          </Box>
          <Organisations />
          <Button
            variant='text'
            loading={logoutMutation.isPending}
            sx={{
              width: '100%',
              marginTop: '1rem',
              textTransform: 'none',
              padding: '.65rem 1.375rem',
              gap: '.5rem',
              color: 'black',
            }}
            onClick={handleLogout}
          >
            <LogoutIcon />
            Logout
          </Button>
        </Box>
      </Menu>
    </>
  );
};
export { UserName };
