import React, { useMemo } from 'react';
import { Box, Typography } from '@zitcha/component-library';
import { formatCurrency } from 'lib/util/currencyFormatter';
import { useGetOrganisation } from 'v2/lib/hooks/useGetOrganisation';

interface RenderCurrencyProps {
  amount: number | undefined;
  label?: string;
  isReadOnly?: boolean;
  showDecimals?: boolean;
  organisationId?: string;
  currencyCode?: string;
  alignLeft?: boolean;
}

export const RenderCurrency = ({
  amount,
  label,
  isReadOnly,
  showDecimals = true,
  organisationId,
  currencyCode,
  alignLeft,
}: RenderCurrencyProps) => {
  const { formatted } = useCurrencyRender({
    amount,
    organisationId,
    currencyCode,
    showDecimals,
  });

  const typographyStyle = isReadOnly ? { color: 'gray', pointerEvents: 'none' } : {};

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        minWidth: '80px',
        textAlign: alignLeft ? 'left' : 'right',
      }}
    >
      {label && (
        <Typography variant='caption' sx={typographyStyle}>
          {label}
        </Typography>
      )}
      <Typography variant='subtitle1' gutterBottom sx={typographyStyle}>
        {formatted}
      </Typography>
    </Box>
  );
};

/**
 * Custom hook to format currency based on the organisation's default currency
 * If amount is not provided, a 0 amount will be used.
 *
 * Use `getFormatted(amount)` if the amount is dynamic, and you want to format it on demand.
 */
export const useCurrencyRender = ({
  amount,
  organisationId,
  currencyCode,
  showDecimals = true,
}: {
  amount: number | undefined;
  organisationId?: string;
  currencyCode?: string;
  showDecimals?: boolean;
}) => {
  const { organisation, isLoading } = useGetOrganisation(organisationId || '');

  const effectiveCurrencyCode = useMemo(() => {
    if (currencyCode) return currencyCode;
    if (organisation?.settings?.defaultCurrency) return organisation.settings.defaultCurrency;
    return '';
  }, [currencyCode, organisation?.settings?.defaultCurrency]);

  const getFormatted = (amount: number | undefined) => {
    if (isLoading) return 'Loading...';
    if (amount === undefined || amount === 0) return '-';
    return formatCurrency(amount, effectiveCurrencyCode, showDecimals);
  };

  const formatted = useMemo(() => getFormatted(amount), [amount, effectiveCurrencyCode, isLoading, showDecimals]);

  return { formatted, getFormatted };
};
