import React from 'react';
import { Button, CircularProgress } from '@zitcha/component-library';
import { MANAGE_SUPPLIER_SETTINGS } from 'lib/permissions';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import OrganisationList from '../../features/organisation_settings/components/List';
import { PageSection } from 'v2/Layouts/PageSection';
import { SettingsTableContainer } from 'app/OrganisationSettings/components/SettingsTableContainer';

export const SupplierSpecificSettings = ({ suppliers, isLoading, navigate }) => {
  const { hasPermission } = usePermissions();
  const canManageSupplierSettings = hasPermission(MANAGE_SUPPLIER_SETTINGS);

  const hasSuppliers = suppliers.length > 0;

  return (
    <PageSection id='supplier-list-section' heading='Supplier list' marginTop={0}>
      <SettingsTableContainer>
        {isLoading ? (
          <CircularProgress />
        ) : hasSuppliers ? (
          <OrganisationList
            organisations={suppliers}
            onClick={(organisation) => {
              if (canManageSupplierSettings) {
                navigate(`/settings/suppliers/${organisation.id}`);
              }
            }}
          />
        ) : (
          <Button variant='contained' color='secondary' onClick={() => navigate('/organisations/invite')}>
            Invite supplier
          </Button>
        )}
      </SettingsTableContainer>
    </PageSection>
  );
};
