import React from 'react';
import { TextField } from '@zitcha/component-library';
import { Ad } from 'v2/lib/api/ad-management';

type SkuCodesProps = {
  ads: Array<Ad>;
  isReadOnly: boolean;
};

// todo we should check for fields of type ProductSelectorComponent instead of fields.SKU
export const SkuCodes: React.FC<SkuCodesProps> = ({ ads, isReadOnly }) => {
  // Extract SKU codes from ads into an array
  const skuCodes = ads.reduce((acc: Array<string>, ad: Ad) => {
    if (Array.isArray(ad?.fields?.SKU)) {
      const adSkuCodes = ad?.fields?.SKU?.map((sku: any) => sku.id) || [];
      return acc.concat(adSkuCodes);
    }
    return acc;
  }, []);

  return <TextField label='SKU code(s)' id='sku-codes' value={skuCodes.join(', ')} disabled={isReadOnly} data-no-cap />;
};
