import React from 'react';
import { Navigate } from 'react-router-dom';

const LoginAuth0 = () => {
  localStorage.setItem('auth0Enabled', 'true');

  return <Navigate to={'/login'} replace />;
};

export default LoginAuth0;
