import React from 'react';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faAngleRight, faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { packageDateRangeText } from '../../../lib/packages';
import PackageRow from './PackageRow';
import { FALLBACK_CURRENCY } from '../../../lib/financial';
import { Button } from '@zitcha/component-library';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const PackageSubheader = styled.h6`
  font-weight: 500;
`;

const PackageLink = styled(Link)`
  text-decoration: none;
  margin-left: auto;
  color: unset;

  &:hover {
    text-decoration: none;
    color: unset;
  }
`;

const PackagesContainer = styled.div``;

const EmptyPackagesContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #ededef;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyTextContainer = styled.div`
  text-align: center;
  max-width: 18.75rem;
`;

const EmptyHeader = styled.h2``;

const EmptyDescription = styled.p``;

const EmptyPackages = () => (
  <section>
    <EmptyPackagesContainer>
      <EmptyTextContainer>
        <EmptyHeader>No Packages</EmptyHeader>
        <EmptyDescription>Create a package to promote a collection of ad channels to your retailers.</EmptyDescription>
        <PackageLink to='/packages/create'>
          <Button size='large' startIcon={<FontAwesomeIcon icon={faPlus} className='mr-3' />}>
            New Package
          </Button>
        </PackageLink>
      </EmptyTextContainer>
    </EmptyPackagesContainer>
  </section>
);

const PackageList = ({ packages = [] }) => {
  if (packages.length === 0) {
    return <EmptyPackages />;
  }

  return (
    <section>
      <HeaderContainer>
        <PackageSubheader>All Packages ({packages.length})</PackageSubheader>
        <PackageLink to='/packages/create'>
          <Button size='large' startIcon={<FontAwesomeIcon icon={faPlus} className='mr-3' />}>
            New Package
          </Button>
        </PackageLink>
      </HeaderContainer>
      <PackagesContainer>
        {packages.map((pkg) => (
          <PackageRow
            active
            key={pkg.id}
            header={`${pkg.name} (${pkg.ad_format_templates.length} ads)`}
            dates={packageDateRangeText(pkg)}
            group={pkg.package_group?.name}
            minSpend={new Intl.NumberFormat(undefined, {
              style: 'currency',
              currency: FALLBACK_CURRENCY,
              maximumFractionDigits: 0,
              currencyDisplay: 'narrowSymbol',
            }).format(pkg.min_budget)}
            actions={
              <div>
                <FontAwesomeIcon icon={faEllipsisV} className='mr-3' size='lg' />

                <PackageLink to={`/packages/${pkg.id}/edit`}>
                  <FontAwesomeIcon icon={faAngleRight} size='lg' />
                </PackageLink>
              </div>
            }
          />
        ))}
      </PackagesContainer>
    </section>
  );
};

export default PackageList;
