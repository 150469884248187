import React from 'react';
import AllOrders from './app/AllOrders';
import ArchivedOrders from './app/ArchivedOrders';
import CreateOrder from './app/CreateOrder';
import EditOrder from './app/EditOrder';
import InviteSupplier from './app/InviteSupplier';
import LinkOrg from './app/LinkOrg';
import LinkUser from './app/LinkUser';
import Login from './app/Login';
import LoginAdminAs from './app/LoginAdminAs';
import EditOwnedSpace from './app/EditOwnedSpace';
import CreateOwnedSpace from './app/CreateOwnedSpace';
import Register from './app/Register';
import RegisterUser from './app/RegisterUser';
import ResetPassword from './app/ResetPassword';
import ResetPasswordConfirm from './app/ResetPasswordConfirm';
import SupplierSettings from './app/SupplierSettings';
import SuppliersGeneralSettings from './app/SuppliersGeneralSettings/SuppliersGeneralSettings';
import ViewOrder from './app/ViewOrder';
import { OrganisationSettings } from './app/OrganisationSettings/OrganisationSettings';
import OrganisationChannels from './app/OrganisationChannels';
import OrganisationWebSettings from './app/OrganisationWebSettings';
import OrganisationBespokeSettings from './app/OrganisationBespokeSettings';
import OrganisationInStoreScreensSettings from './app/OrganisationSettings/Broadsign/OrganisationInStoreScreensSettings';
import CreateBespokeProduct from './app/CreateBespokeProduct';
import EditBespokeProduct from './app/EditBespokeProduct';
import ListPackages from './app/ListPackages';
import CreatePackage from './app/CreatePackage';
import RetailerWallets from './app/RetailerWallets';
import SingleWalletPage from './app/SingleWalletPage';
import EditPackage from './app/EditPackage';
import OrganisationInventoryHome from './features/inventory/OrganisationInventoryHome';
import OrganisationInventoryWebspaceDetails from './features/inventory/OrganisationInventoryWebspaceDetails';
import OrganisationInventoryAudienceDetails from './features/inventory/OrganisationInventoryAudienceDetails';
import { EditBrandStore } from './features/brand_stores/EditBrandStore';
import OrganisationBrandStoresHome from './features/brand_stores/OrganisationBrandStoresHome';
import BrandStoreConfigureForm from './features/brand_stores/create/BrandStoreConfigureForm';
import { PlansAdSetsView } from 'v2/features/Planning/PlansAdSetsView';
import { NewPlanView } from 'v2/features/Planning/NewPlanPage/NewPlanView';
import { Box, FactCheckIcon, InventoryIcon } from '@zitcha/component-library';
import { PlanView } from './v2/features/Planning/PlanPage/PlanView';
import RetailerSettings from './app/OrganisationSettings/RetailerList/RetailerSettings';
import { InventoryPage } from 'v2/features/Inventory/InventoryPage';
import { BaseLayout } from 'v2/Layouts/BaseLayout/BaseLayout';
import Logout from 'v2/features/Authentication/Logout';
import Auth0ProviderLayout from 'v2/Layouts/Auth0ProviderLayout';
import ProtectedRoutes from 'v2/features/Authorization/ProtectedRoutes';
import Callback from './v2/features/Authentication/Callback';
import LoginAuth0 from './v2/features/Authentication/LoginAuth0';
import { DashboardContainer } from 'v2/features/Dashboard/DashboardContainer';
import { RouteErrorBoundary, Throw404 } from './app/RouteErrorBoundary';

// todo - turn into from a constant, put boolean logic into the components
export const getRoutes = (featureFlags, hideInventoryRoute) => {
  const planningEnabled = featureFlags?.feature_planning;
  return [
    {
      element: <Auth0ProviderLayout />,
      errorElement: <RouteErrorBoundary />,
      children: [
        { path: 'login', element: <Login />, handle: { crumb: 'Login' } },
        { path: 'login-auth0', element: <LoginAuth0 />, handle: { crumb: 'Login' } },
        { path: 'admin-login-as', element: <LoginAdminAs />, handle: { crumb: 'Admin Login' } },
        { path: 'register', element: <Register />, handle: { crumb: 'Register' } },
        { path: 'register/user', element: <RegisterUser />, handle: { crumb: 'Register User' } },
        { path: 'organisation/link', element: <LinkOrg />, handle: { crumb: 'Link Organisation' } },
        { path: 'user/link', element: <LinkUser />, handle: { crumb: 'Link User' } },
        { path: 'reset', element: <ResetPassword />, handle: { crumb: 'Reset Password' } },
        { path: 'logout', element: <Logout />, handle: { crumb: 'Log Out' } },
        { path: 'callback', element: <Callback /> },
        {
          path: 'reset/password/:token',
          element: <ResetPasswordConfirm />,
          handle: { crumb: 'Confirm password reset' },
        },
        {
          path: '/',
          element: <ProtectedRoutes />,
          children: [
            {
              index: true,
              element: <DashboardContainer />,
              handle: { crumb: 'Dashboard' },
            },
            {
              path: 'orders',
              element: <BaseLayout heading='Orders' size='lg' />,
              handle: {
                crumb: 'All orders',
              },
              children: [{ index: true, element: <AllOrders /> }],
            },
            {
              path: 'orders/archive',
              element: <BaseLayout heading='Archived orders' size='lg' />,
              handle: {
                crumb: 'Archived orders',
              },
              children: [{ index: true, element: <ArchivedOrders /> }],
            },
            { path: 'order/create', element: <CreateOrder />, handle: { crumb: 'Create order' } },
            { path: 'order/:order_id', element: <ViewOrder />, handle: { crumb: 'View order' } },
            { path: 'order/edit/:order_id', element: <EditOrder />, handle: { crumb: 'Edit order' } },
            {
              path: 'brandstores/configure',
              element: <BrandStoreConfigureForm />,
              handle: { crumb: 'Configure brand store' },
            },
            {
              path: 'brandstores/configure/:order_id',
              element: <BrandStoreConfigureForm />,
              handle: { crumb: 'Configure brand store' },
            },
            { path: 'brandstores/home', element: <OrganisationBrandStoresHome />, handle: { crumb: 'Brand stores' } },
            { path: 'brandstores/edit/:order_id', element: <EditBrandStore />, handle: { crumb: 'Edit Brand store' } },
            { path: 'organisations/invite', element: <InviteSupplier />, handle: { crumb: 'Invite supplier' } },
            { path: 'inventory/home', element: <OrganisationInventoryHome />, handle: { crumb: 'Inventory' } },
            {
              path: 'inventory/ownedspace/usage/:usage_id',
              element: <OrganisationInventoryWebspaceDetails />,
              handle: { crumb: 'Webspace usage details' },
            },
            {
              path: 'inventory/audience/usage/:audience_id',
              element: <OrganisationInventoryAudienceDetails />,
              handle: { crumb: 'Audience usage details' },
            },
            {
              path: 'organisation/settings',
              element: <OrganisationSettings />,
              handle: { crumb: 'Organisation settings' },
            },
            {
              path: 'organisation/channels',
              element: <OrganisationChannels />,
              handle: { crumb: 'Organisation channels' },
            },
            {
              path: 'organisation/websettings',
              element: <OrganisationWebSettings />,
              handle: { crumb: 'Web settings' },
            },
            {
              path: 'organisation/instorescreens-settings',
              element: <OrganisationInStoreScreensSettings />,
              handle: { crumb: 'In-store screens settings' },
            },
            {
              path: 'organisation/bespokesettings',
              element: <OrganisationBespokeSettings />,
              handle: { crumb: 'Bespoke settings' },
            },
            { path: 'wallets', element: <RetailerWallets />, handle: { crumb: 'Retailer wallets' } },
            { path: 'wallet/:wallet_id', element: <SingleWalletPage />, handle: { crumb: 'Single wallet page' } },
            {
              path: 'organisation/websettings/edit/:owned_space_id',
              element: <EditOwnedSpace />,
              handle: { crumb: 'Edit owned space' },
            },
            {
              path: 'organisation/websettings/create/:placement/:format/:subformat',
              element: <CreateOwnedSpace />,
              handle: { crumb: 'Create owned space' },
            },
            {
              path: 'settings/suppliers',
              element: <SuppliersGeneralSettings />,
              handle: { crumb: 'Supplier settings' },
            },
            {
              path: 'settings/suppliers/:organisation_id',
              element: <SupplierSettings />,
              handle: { crumb: 'Supplier settings' },
            },
            {
              path: 'organisation/settings/retailers/:organisationId',
              element: <RetailerSettings />,
              handle: { crumb: 'Retailer settings' },
            },
            { path: 'packages', element: <ListPackages />, handle: { crumb: 'List packages' } },
            { path: 'packages/create', element: <CreatePackage />, handle: { crumb: 'Create package' } },
            { path: 'packages/:package_id/edit', element: <EditPackage />, handle: { crumb: 'Edit package' } },
            {
              path: 'organisation/bespokesettings/create',
              element: <CreateBespokeProduct />,
              handle: { crumb: 'Create bespoke product' },
            },
            {
              path: 'organisation/bespokesettings/:bespoke_id/edit',
              element: <EditBespokeProduct />,
              handle: { crumb: 'Edit bespoke product' },
            },
            {
              path: 'plans-ad-sets',
              element: <Throw404 when={!planningEnabled} otherwise={<BaseLayout />} />,
              handle: {
                crumb: (
                  <Box display='flex' alignItems='center' gap={1}>
                    <FactCheckIcon />
                    <span>Plans & ad sets</span>
                  </Box>
                ),
              },
              children: [
                {
                  index: true,
                  element: <PlansAdSetsView />,
                },
                {
                  path: 'new-plan',
                  element: <NewPlanView />,
                  handle: { crumb: 'New plan' },
                },
                {
                  path: ':planId',
                  element: <PlanView />,
                  handle: { crumb: 'Plan' },
                },
              ],
            },
            {
              path: 'inventory',
              element: (
                <Throw404
                  when={!planningEnabled || hideInventoryRoute}
                  otherwise={<BaseLayout heading='Inventory' />}
                />
              ),
              handle: {
                crumb: (
                  <Box display='flex' alignItems='center' gap={1}>
                    <InventoryIcon />
                    <span>Inventory</span>
                  </Box>
                ),
              },
              children: [{ index: true, element: <InventoryPage /> }],
            },
          ],
        },
      ],
    },
  ];
};
