import React, { ChangeEvent, FC, useState } from 'react';
import { Box, InputAdornment, Select, TextField } from '@zitcha/component-library';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSession } from 'features/session/sessionSlice';
import { DiscountData } from './DiscountsModal';
import { CreateDiscountRequestType } from 'v2/lib/api/ad-management/model/createDiscountRequestType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

interface DiscountSelectorProps {
  variant: 'standard' | 'outlined';
  showLabel?: boolean;
  onChange: (discount: DiscountData) => void;
  readOnly?: boolean;
  row?: DiscountData;
  label?: string;
  fullWidth?: boolean;
  helperText?: string;
}

export const DiscountSelector: FC<DiscountSelectorProps> = ({
  variant = 'standard',
  showLabel = false,
  readOnly = false,
  onChange,
  row,
  label,
  fullWidth,
  helperText,
}) => {
  const { currencies } = useSelector(selectSession);
  const [selected, setSelected] = useState<string>(row?.type ?? 'percentage');
  const [value, setValue] = useState<string>(row?.discount ?? '0');
  const {
    settings: { defaultCurrency },
  } = useUserOrganisation();

  const currency = currencies.filter((currency: Record<string, string>) => currency.code === defaultCurrency)[0];

  const handleSelected = (e: SelectChangeEvent<string>) => {
    const selectedType = e.target.value;

    setSelected(selectedType);
    onChange({ ...row, discount: value, type: selectedType });
  };

  const handleValue = (e: ChangeEvent<HTMLInputElement>) => {
    let selectedValue = e.target.value;
    // Remove initial "0" if the user starts typing
    if (value === '0' && selectedValue !== '0') {
      selectedValue = selectedValue.replace(/^0+/, '');
    }

    setValue(selectedValue);
    onChange({ ...row, discount: selectedValue, type: selected });
  };

  // Apply read-only styling conditionally
  const readOnlyStyle = readOnly ? { color: 'gray', pointerEvents: 'none' } : {};

  return (
    <Box
      display='flex'
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {showLabel && (
        <label aria-label='Amount off' className='twd-tracking-0 twd-text-xs twd-text-black/60 twd-mb-0'>
          Amount off
        </label>
      )}
      <TextField
        type='number'
        variant={variant}
        label={label}
        fullWidth={fullWidth}
        style={{ minWidth: 120, lineHeight: 0 }}
        value={value}
        onChange={handleValue}
        helperText={helperText}
        InputProps={{
          readOnly: readOnly,
          inputProps: { min: 0 },
          disableUnderline: true,
          style: {
            width: 'auto',
            minWidth: '0',
            paddingLeft: 0,
            alignItems: 'stretch',
            overflow: 'hidden',
            ...readOnlyStyle,
          },
          startAdornment: (
            <InputAdornment
              position='start'
              component={() => (
                <>
                  <Select
                    onChange={handleSelected}
                    variant='standard'
                    value={selected}
                    disableUnderline
                    margin='none'
                    readOnly={readOnly}
                    sx={{
                      marginRight: 1,
                      fontWeight: 'bold',
                      '.MuiSelect-select': { paddingLeft: variant === 'standard' ? 0 : '16px' },
                      ...readOnlyStyle,
                    }}
                  >
                    <MenuItem value={CreateDiscountRequestType.percentage}>%</MenuItem>
                    <MenuItem value={CreateDiscountRequestType.fixed}>{currency?.symbol}</MenuItem>
                  </Select>
                </>
              )}
            ></InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
