import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import OwnedSpaceCategory from '../features/owned_space_form/containers/OwnedSpaceCategory';
import OwnedSpaceMonetisation from '../features/owned_space_form/containers/OwnedSpaceMonetisation';
import OwnedSpaceName from '../features/owned_space_form/containers/OwnedSpaceName';
import OwnedSpaceSlots from '../features/owned_space_form/containers/OwnedSpaceSlots';
import OwnedTemplates from '../features/owned_space_form/containers/OwnedTemplates';
import OwnedUrls from '../features/owned_space_form/containers/OwnedUrls';
import OwnedSpaceAllowedOrganisations from '../features/owned_space_form/containers/OwnedSpaceAllowedOrganisations';
import {
  clearOwnedSpace,
  createOwnedSpace,
  ownedSpaceFieldUpdated,
  selectOwnedSpace,
  selectUserOrganisation,
} from '../features/owned_space_form/ownedSpaceFormSlice';
import { trackCreateSpace } from '../helpers/segment';
import { Button } from '@zitcha/component-library';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const CreateOwnedSpace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { placement, format, subformat } = useParams();
  const ownedSpace = useSelector(selectOwnedSpace);
  const userOrganisation = useSelector(selectUserOrganisation);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(clearOwnedSpace());
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'format',
        value: format,
      })
    );
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'subformat',
        value: subformat,
      })
    );
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'placement',
        value: placement,
      })
    );
    dispatch(
      ownedSpaceFieldUpdated({
        field: 'organisation_id',
        value: userOrganisation.id,
      })
    );
  }, [dispatch]);

  if (!ownedSpace) {
    return <Navigate to='/organisation/websettings' replace />;
  }

  const customBreadcrumbs = [
    {
      path: '/organisation/settings',
      title: 'Settings',
    },
    {
      path: '/organisation/channels',
      title: 'Owned channels',
    },
    {
      path: '/organisation/websettings',
      title: 'Spaces & templates',
    },
  ];

  return (
    <BaseLayoutWithChildren
      heading={`Create Space${ownedSpace.name && `: ${ownedSpace.name}`}`}
      size='lg'
      customBreadcrumbs={customBreadcrumbs}
    >
      <PageSection id='create-owned-space'>
        <Form>
          <div className='mb-5 d-flex'>
            <div>
              <Badge className='text-capitalize' variant='info'>
                {ownedSpace.placement} Space
              </Badge>
              <span className='ml-2'>
                <b>Format:</b> {ownedSpace.format ? ownedSpace.format : '-'}
              </span>
              <span className='ml-2'>
                <b>Subformat:</b> {ownedSpace.subformat ? ownedSpace.subformat : '-'}
              </span>
            </div>
            <div className='ml-auto'>
              <Button
                size='large'
                onClick={() => {
                  setIsSaving(true);
                  dispatch(createOwnedSpace()).then((action) => {
                    if (!action.error) {
                      const space = action.payload;
                      trackCreateSpace({ ...space, placement: 'embedded' });
                      navigate(`/organisation/websettings`);
                    } else {
                      setIsSaving(false);
                    }
                  });
                }}
                disabled={isSaving}
              >
                <span className='small d-flex'>
                  {isSaving && (
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' className='mr-2' />
                  )}
                  <span>Create Space</span>
                </span>
              </Button>
            </div>
          </div>
          <div className='mb-5'>
            <h5>General Settings</h5>
            <hr />
            <Container fluid>
              <Row>
                <Col>
                  <OwnedSpaceName />
                </Col>
                <Col>
                  <OwnedSpaceSlots />
                </Col>
                {ownedSpace?.subformat === 'web' && (
                  <Col>
                    <OwnedSpaceCategory />
                  </Col>
                )}
              </Row>
            </Container>
          </div>
          <section>
            <h5>Space Details</h5>
            <hr />
            <Container fluid>
              <Row>
                {ownedSpace.placement !== 'embedded' && (
                  <Col>
                    <OwnedUrls />
                  </Col>
                )}
                <Col>
                  <OwnedSpaceMonetisation />
                </Col>
                <Col>
                  <OwnedSpaceAllowedOrganisations />
                </Col>
                <Col>
                  <OwnedTemplates />
                </Col>
              </Row>
            </Container>
          </section>
        </Form>
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default CreateOwnedSpace;
