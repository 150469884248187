import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@zitcha/component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const NoWallet = ({ organisation, isSupplier = false, handleNewWalletBtn = () => {} }) => {
  return (
    <div className='d-flex flex-column align-items-center pt-4 pb-5 border'>
      {!isSupplier ? (
        <>
          <h3 className='my-4'>No Wallets for {organisation?.name}</h3>
          <Button onClick={handleNewWalletBtn} type='button' startIcon={<FontAwesomeIcon icon={faPlus} size='lg' />}>
            New
          </Button>
        </>
      ) : (
        <>
          <h3 className='mt-3'>No Wallets</h3>
          <p>Wallet allocations assigned to you by retailers will be shown here.</p>
        </>
      )}
    </div>
  );
};

NoWallet.propTypes = {
  organisation: propTypes.shape({
    id: propTypes.isRequired,
    name: propTypes.string.isRequired,
  }),
  iSupplier: propTypes.bool,
  handleNewWalletBtn: propTypes.func,
};

export default NoWallet;
