import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { auth0Enabled } from '../../../envVars';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
export const API_TIMEOUT_TIME = 60000;
export const AXIOS_INSTANCE = Axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT_TIME,
});

// This is to setup an already existing instance, the reason for this function is to avoid infinite loop
// when importing axios instance in /api files which in turn getting imported in each redux slice file
// TODO: refactor this once we have the session id or token stored in cookies instead of the "store"
export const axiosSetUp = (
  instance: AxiosInstance,
  store: any,
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  isAuthenticated: boolean
) => {
  instance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    let token;
    if (auth0Enabled() && isAuthenticated) {
      token = await getAccessTokenSilently();
    } else {
      token = store.getState().session?.authToken;
    }

    if (token) {
      config.headers.setAuthorization(`Bearer ${token}`);
    }

    return config;
  });
};

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type,
// so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
export default AXIOS_INSTANCE;
