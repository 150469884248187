import React from 'react';
import { SimpleLoadingScreen } from 'v2/components/SimpleLoadingScreen';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';
import { Dashboard } from './Dashboard';
import LegacyDashboard from 'app/Dashboard';

export const DashboardContainer = () => {
  const { data: featureFlags, isPending } = useGetFeatureFlags();

  if (isPending) {
    return <SimpleLoadingScreen />;
  }

  const planningEnabled = featureFlags?.feature_planning;

  if (planningEnabled) {
    return <Dashboard />;
  } else {
    return <LegacyDashboard />;
  }
};
