import React, { useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import FileDropzone from '../controls/FileDropzone';
import Errors from '../Errors';
import { postAsset } from '../../../lib/api';
import FileSquare from './Assets/FileSquare';
import { Asset } from '../types';

type FileUploadProps = {
  value: Array<Asset>;
  accept: Array<string>;
  max: number;
  errors?: Array<string>;
  readOnly?: boolean;

  onChange: (newValue: Array<Asset>) => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;

  postRequest?: (File, any) => Promise<any>;
};

const FileUpload = ({
  value,
  accept = [],
  max,
  readOnly = false,
  errors = [],
  onChange = () => {
    /* do nothing */
  },
  onUploadStart = () => {
    /* do nothing */
  },
  onUploadEnd = () => {
    /* do nothing */
  },
  postRequest = postAsset,
}: FileUploadProps) => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [uploadErrors, setUploadErrors] = useState<Array<string>>([]);

  let progressBar;
  if (progress !== undefined) {
    progressBar = (
      <ProgressBar className='mt-2' variant={progress === 100 ? 'success' : 'primary'} animated now={progress} />
    );
  }

  const resetUploadErrors = () => setUploadErrors([]);

  const appendUploadError = (error: string) => {
    if (!uploadErrors.includes(error)) {
      setUploadErrors([...uploadErrors, error]);
    }
  };

  const handleFileUpload = async (files: FileList) => {
    resetUploadErrors();
    onUploadStart();

    if (value.length + files.length > max) {
      appendUploadError(`Uploaded too many files: max ${max}`);
      onUploadEnd();
      return;
    }

    const newValue = [...value];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fext = file.name.split('.').pop().toLowerCase();

      if (!accept.some((e) => e === fext)) {
        appendUploadError(`Invalid file type, only accepted: ${accept.join(', ')}`);
        break;
      }

      let resp;
      try {
        resp = await postRequest(file, {
          onUploadProgress: (p) => setProgress((p.loaded / p.total) * 100),
        });
      } catch (e) {
        if (e.response?.status === 400) {
          appendUploadError(e.response.data.error);
        } else {
          appendUploadError('Something bad happened. Please try again later.');
        }

        setProgress(null);
        break;
      }

      const fileJson = resp.data.data;

      newValue.push(fileJson);
    }
    onUploadEnd();
    setProgress(undefined);

    onChange(newValue);
  };

  const handleFileDelete = (file: Asset) => onChange(value.filter((f) => f.id !== file.id));
  const allErrors = [...errors, ...uploadErrors];

  return (
    <div>
      <FileDropzone onChange={handleFileUpload} readOnly={readOnly || value.length >= max}>
        {value.map((file) => (
          <FileSquare key={file.id} file={file} readOnly={readOnly} onDelete={() => handleFileDelete(file)} />
        ))}
      </FileDropzone>
      <Form.Control.Feedback className={allErrors.length > 0 ? 'd-block' : ''} type='invalid'>
        <Errors errors={allErrors} />
      </Form.Control.Feedback>
      {progressBar}
      <small>
        Accepts up to {max} files. Accepted file extensions: {accept.join(', ')}.
      </small>
    </div>
  );
};

export default FileUpload;
