import {
  DiscountForType,
  UpdateDiscountRequestType,
  useDeleteDiscount,
  useCreateDiscount,
  useUpdateDiscount,
} from 'v2/lib/api/ad-management';
import { AdSetData } from './PlanContext';

export const useDiscounts = () => {
  const addDiscounts = useCreateDiscount();
  const updateDiscounts = useUpdateDiscount();
  const deleteDiscounts = useDeleteDiscount();

  const syncAdSetsDiscounts = (updatedAdSets: Array<AdSetData>): Array<() => Promise<unknown>> => {
    return updatedAdSets.map((adSet) => {
      return () => {
        const discount = adSet?.discount || null;
        const discountFor = [{ id: adSet.id, type: DiscountForType.ad_set }];

        if (discount?.value === 0 && discount?.id) {
          return deleteDiscounts.mutateAsync({ discountId: discount.id });
        }

        if (discount?.value && discount?.value > 0) {
          if (discount?.id) {
            return updateDiscounts.mutateAsync({
              discountId: discount.id,
              data: {
                value: discount.value,
                type: discount.type as UpdateDiscountRequestType,
                discountables: discountFor,
              },
            });
          } else {
            return addDiscounts.mutateAsync({
              data: {
                value: discount.value,
                type: discount.type as UpdateDiscountRequestType,
                discountables: discountFor,
              },
            });
          }
        }

        return Promise.resolve();
      };
    });
  };

  return {
    syncAdSetsDiscounts,
  };
};
