import React from 'react';
import { useSelector } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AppLoading from 'v2/components/AppLoading';
import { auth0Enabled } from '../../../envVars';
import { Navigate, useLocation } from 'react-router-dom';
import ProtectedInner from './ProtectedInner';

// There are 3 paths for the protected route that can happen.
// 1. Auth0 is disabled and the user is not authenticated. Redirect to login page.
// 2. Auth0 is enabled and the user is not authenticated. Send the user to the Auth0 login page.
// 3. Auth0 is enabled and the user is authenticated. Show the component.
const ProtectedRoutes = () => {
  const session = useSelector((state) => state.session);
  const location = useLocation();
  const userAuthed = session?.authToken != null;

  if (userAuthed) {
    return <ProtectedInner />;
  }

  if (auth0Enabled()) {
    const Component = withAuthenticationRequired(ProtectedInner, { onRedirecting: () => <AppLoading /> });
    return <Component />;
  }

  const loginUrl = '/login?postLoginPath=' + location.pathname;
  return <Navigate to={loginUrl} replace />;
};

export default ProtectedRoutes;
