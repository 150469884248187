import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { selectUserOrganisation } from '../../../session/sessionSlice';
import PackageAdFormatForm from '../../containers/PackageForm/PackageAdFormatForm';
import PackageAdFormatSelect from '../../containers/PackageForm/PackageAdFormatSelect';
import { selectFormErrors, upsertPackage } from '../../packageFormSlice';
import PackageCard from '../PackageCard';
import ActionBar from './ActionBar';
import DetailsForm from './DetailsForm';
import { Button } from '@zitcha/component-library';

const StyledModal = styled(Modal)`
  .modal-header {
    background-color: #f8f8f8;
    border-bottom: 0;
    .modal-title {
      font-size: 18px;
    }
  }
  .modal-body {
    .title {
      text-align: center;
    }
    article {
      margin: auto;
    }
  }
  .modal-footer {
    button {
      margin: 10px auto;
    }
    border: 0;
  }
`;

const PackageForm = ({ canFullEdit = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const packages = useSelector((state) => state.package_form);
  const organisation = useSelector(selectUserOrganisation);
  const formErrors = useSelector(selectFormErrors);

  return (
    <form>
      <Row>
        <Col lg='4' xl='3'>
          <DetailsForm canFullEdit={canFullEdit} />
        </Col>
        <Col lg='8' xl='9'>
          <Row className='mb-4'>
            <Col>
              <ActionBar
                onCancel={() => {
                  navigate('/packages');
                }}
                onPreview={() => setShow(!show)}
                onSave={() => {
                  dispatch(upsertPackage()).then((action) => {
                    if (!action.meta.rejectedWithValue) {
                      navigate('/packages');
                    }
                  });
                }}
                errors={formErrors}
              />
            </Col>
          </Row>
          <Row>
            <Col xl='4' lg='5' md='6'>
              <PackageAdFormatSelect readOnly={!canFullEdit} />
            </Col>
            <Col xl='8' lg='7' md='6'>
              <PackageAdFormatForm readOnly={!canFullEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
      <StyledModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Preview Your Package Listing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='title'>This is how your package listing will be advertised to suppliers.</p>
          <PackageCard
            image={packages.package.image}
            organisation={organisation}
            header={packages.package.name || 'Enter Package Name'}
            description={packages.package.description || 'Enter Package Description'}
            outcomes={packages.package.outcomes || 'Enter Package Outcomes'}
            ads={packages.package.ad_format_templates}
            showButton={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outlined' onClick={handleClose}>
            Close Preview
          </Button>
        </Modal.Footer>
      </StyledModal>
    </form>
  );
};

PackageForm.propTypes = {
  canFullEdit: PropTypes.bool,
};

export default PackageForm;
