import React from 'react';
import { Container, Typography } from '@zitcha/component-library';
import { ContainerProps } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbItem, CustomBreadcrumbs } from 'v2/components/Breadcrumbs/CustomBreadcrumbs';
import { DynamicBreadcrumbs } from 'v2/components/Breadcrumbs/DynamicBreadcrumbs';

interface BaseLayoutContentProps {
  children: React.ReactNode;
  heading?: string;
  size?: ContainerProps['maxWidth'];
  customBreadcrumbs?: Array<BreadcrumbItem>;
}

export const BaseLayoutContent = ({ children, heading, size = 'xl', customBreadcrumbs }: BaseLayoutContentProps) => {
  return (
    <>
      {heading && (
        <Helmet>
          <title>
            {heading} - {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      <Container maxWidth={size} sx={{ my: 3, width: '100%' }}>
        {customBreadcrumbs ? (
          <CustomBreadcrumbs items={customBreadcrumbs} showHomeIcon={true} />
        ) : (
          <DynamicBreadcrumbs showHomeIcon={true} />
        )}
        {heading && (
          <Typography variant='h4' data-testid='pageHeading'>
            {heading}
          </Typography>
        )}
        {children}
      </Container>
    </>
  );
};
