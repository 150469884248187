import React, { useEffect } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import OrganisationPackageList from '../features/packages/containers/OrganisationPackageList';
import { fetchPackages, selectPackagesRequestStatus } from '../features/packages/packagesSlice';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const Header = styled.h1`
  margin-bottom: 100px;
`;

const ListPackages = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectPackagesRequestStatus);

  useEffect(() => {
    dispatch(fetchPackages());
  }, [dispatch]);

  return (
    <BaseLayoutWithChildren heading='Packages' size='lg'>
      <PageSection id='package-list-section'>
        {status === 'loading' ? (
          <Row className='text-center d-flex align-items-center justify-content-center'>
            <Spinner animation='border' />
            &nbsp;
          </Row>
        ) : (
          <OrganisationPackageList />
        )}
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default ListPackages;
