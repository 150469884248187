import React, { useEffect } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PackageForm from '../features/packages/components/PackageForm';
import {
  clearPackage,
  fetchPackage,
  fetchPackageGroups,
  selectCanFullEdit,
  selectRequestType,
  selectStatus,
} from '../features/packages/packageFormSlice';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const Header = styled.h1`
  margin-bottom: 70px;
`;

const EditPackage = () => {
  const dispatch = useDispatch();
  const { package_id } = useParams();
  const requestStatus = useSelector(selectStatus);
  const requestType = useSelector(selectRequestType);
  const canFullEdit = useSelector(selectCanFullEdit);

  useEffect(() => {
    dispatch(clearPackage());
    dispatch(fetchPackage(package_id));
    dispatch(fetchPackageGroups());
  }, [dispatch, package_id]);

  if (requestStatus === 'failed' && requestType === 'fetch') {
    return <Navigate to='/packages' replace />;
  }

  return (
    <BaseLayoutWithChildren heading='New package'>
      <PageSection id='package-editor-section'>
        {requestStatus === 'loading' && requestType === 'fetch' ? (
          <Row className='text-center d-flex align-items-center justify-content-center'>
            <Spinner animation='border' />
            &nbsp;
          </Row>
        ) : (
          <PackageForm canFullEdit={canFullEdit} />
        )}
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default EditPackage;
