import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PackageForm from '../features/packages/components/PackageForm';
import { clearPackage, fetchPackageGroups } from '../features/packages/packageFormSlice';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const CreatePackage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPackage());
    dispatch(fetchPackageGroups());
  }, [dispatch]);

  return (
    <BaseLayoutWithChildren heading='Package editor'>
      <PageSection id='package-editor-section'>
        <PackageForm />
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default CreatePackage;
