import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CheckIcon,
  ContentCopyIcon,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from '@zitcha/component-library';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; //TODO: replace with component-library import
import {
  AdSet,
  Location,
  CalendarPeriod,
  Ad,
  type AdSetDiscount,
  UpdateAdsInAdSetBodyAdsItem,
  UpdateAdsInAdSetBodyAdsItemFields,
  UpdateAdsInAdSetBodyAdsItemFieldsAnyOf,
} from 'v2/lib/api/ad-management';
import { DetailsTab } from 'v2/components/AdSetModal/DetailsTab';
import { MediaSpace } from 'v2/lib/api/inventory';
import { AdsTab } from './AdsTab';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { AdSetProvider } from './AdSetContext';
import { useCopyToClipboard } from 'v2/lib/hooks/useCopyToClipboard';

export interface AdSetFormValues {
  name: string;
  bundleId: string | null;
  schedule: CalendarPeriod | null;
  mediaSpace: MediaSpace | null;
  placement: Location | null;
  rate: number;
  discount: AdSetDiscount | null;
  budget: number;
  ads: Array<Ad>;
}

interface ApiError extends Error {
  response?: {
    data?: {
      errors?: Record<string, Array<string>>;
    };
  };
}

const transformAdFields = (ad: Ad): UpdateAdsInAdSetBodyAdsItem => {
  const transformedFields: UpdateAdsInAdSetBodyAdsItemFields = {};
  if (!ad.fields) {
    return {
      id: ad.id,
      name: ad.name ?? '',
      fields: transformedFields,
    };
  }
  Object.entries(ad.fields).forEach(([key, value]) => {
    if (key !== 'length' && key !== 'prototype') {
      if (typeof value === 'object' && value !== null) {
        transformedFields[key] = value as UpdateAdsInAdSetBodyAdsItemFieldsAnyOf;
      } else {
        transformedFields[key] = value as string | number | boolean;
      }
    }
  });

  return {
    id: ad.id,
    name: ad.name ?? '',
    fields: transformedFields,
  };
};

export const AdSetModal = ({
  isOpen,
  isReadOnly,
  adSet: existingAdSet,
  onClose,
  saveHandler,
  retailerId,
  brandId,
  initialErrors,
}: AdSetModalProps) => {
  const [selectedAdSetsTab, setSelectedAdSetsTab] = useState<AdSetsTabValue>('details');
  const [processing, setProcessing] = useState(false);
  const [loadingBundle, setLoadingBundle] = useState(false);
  const [readyAds, setReadyAds] = useState<Set<number>>(new Set());
  const { copySuccess, copyToClipboard } = useCopyToClipboard();

  const { control, handleSubmit, setValue, watch, setError, clearErrors } = useForm<AdSetFormValues>({
    defaultValues: {
      name: existingAdSet?.name || '',
      bundleId: existingAdSet?.bundleIds?.[0] || null,
      schedule: existingAdSet?.schedule || null,
      mediaSpace: existingAdSet?.mediaSpace || null,
      placement: null,
      rate: 0,
      discount: existingAdSet?.discount || null,
      budget: 0,
      ads: existingAdSet?.ads ?? [],
    },
  });

  const { errors } = useFormState({ control });
  const hasAdsErrors = Object.keys(errors).some((key) => key.startsWith('ads'));

  const selectedMediaSpace = useWatch({
    control,
    name: 'mediaSpace',
    defaultValue: existingAdSet?.mediaSpace || null,
  });

  const previousMediaSpaceRef = useRef(selectedMediaSpace);

  const handleErrors = (errors: Record<string, Array<string>>, currentAds: Array<Ad>) => {
    const newReadyAds = new Set(currentAds.map((_, index) => index));

    Object.keys(errors).forEach((key) => {
      setError(key as keyof AdSetFormValues, {
        type: 'manual',
        message: errors[key].join('\n'),
      });

      if (key.startsWith('ads.')) {
        const adIndex = parseInt(key.split('.')[1]);
        if (!isNaN(adIndex)) {
          newReadyAds.delete(adIndex);
        }
      }
    });

    setReadyAds(newReadyAds);
  };

  // Set initial errors if provided
  const ads = watch('ads');
  useEffect(() => {
    if (initialErrors) {
      handleErrors(initialErrors, ads);
    }
  }, [initialErrors, ads]);

  // Clear errors when field changes
  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (name && type === 'change') {
        clearErrors(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, clearErrors]);

  // clear ads and placement when media space changes
  useEffect(() => {
    if (selectedMediaSpace !== previousMediaSpaceRef.current) {
      setValue('ads', []);
      previousMediaSpaceRef.current = selectedMediaSpace;
      setValue('placement', null);
    }
  }, [selectedMediaSpace, setValue]);

  const handleTabChange = (_: SyntheticEvent, newValue: AdSetsTabValue) => setSelectedAdSetsTab(newValue);

  const onSubmit = (data: AdSetFormValues) => {
    const updatedAdSet: AdSet = {
      ...existingAdSet,
      name: data.name,
      bundleIds: [data.bundleId || ''],
      mediaSpace: data.mediaSpace || undefined,
      schedule: data.schedule || undefined,
      placement: data.placement || undefined,
      discount: data.discount || undefined,
      ads: data.ads.map(transformAdFields),
    };

    if (saveHandler) {
      setProcessing(true);
      saveHandler(updatedAdSet)
        .catch((e: ApiError) => {
          if (e.response && e.response.data && e.response.data.errors) {
            handleErrors(e.response.data.errors, data.ads);
          }
        })
        .finally(() => setProcessing(false));
    }
  };

  const bundleIdNotFound = !watch('bundleId') && !loadingBundle;

  const adSetId = existingAdSet?.id;
  const CopyAdSet = adSetId ? (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }} gap={1}>
      <Typography variant='caption'>ID:</Typography>
      <IconButton size='small' onClick={() => copyToClipboard(adSetId)} aria-label='Copy ad set ID'>
        {copySuccess ? <CheckIcon fontSize='small' /> : <ContentCopyIcon fontSize='small' />}
      </IconButton>
      <Typography variant='caption'>{adSetId}</Typography>
    </Box>
  ) : null;

  return (
    <Modal open={isOpen} onClose={onClose} size='large' title={'Ad set'} additionalTitleContent={CopyAdSet}>
      <AdSetProvider adSet={existingAdSet} retailerId={retailerId} brandId={brandId}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid='ad-set-form'>
          {!isReadOnly && (
            <Box mb={1} mt={1}>
              {bundleIdNotFound && <Alert severity='error'>No ad set available.</Alert>}
            </Box>
          )}
          <Tabs value={selectedAdSetsTab} onChange={handleTabChange} aria-label='Ad set tabs'>
            <Tab
              {...(bundleIdNotFound && { icon: <ErrorOutlineIcon color='error' /> })}
              iconPosition={'start'}
              label='Details'
              value={'details'}
              sx={{
                minHeight: '35px',
              }}
            />
            <Tab
              {...(hasAdsErrors && { icon: <ErrorOutlineIcon color='error' /> })}
              iconPosition={'start'}
              label={'Ads'}
              value={'ads'}
              sx={{
                minHeight: '35px',
              }}
            />
          </Tabs>
          <div style={{ display: selectedAdSetsTab === 'details' ? 'block' : 'none' }} data-testid='details-section'>
            <DetailsTab
              control={control}
              setValue={setValue}
              existingAdSet={existingAdSet}
              retailerId={retailerId || ''}
              isReadOnly={isReadOnly}
              setLoadingBundle={setLoadingBundle}
              hadBundleError={bundleIdNotFound}
            />
          </div>
          <div style={{ display: selectedAdSetsTab === 'ads' ? 'block' : 'none' }} data-testid='ads-section'>
            <AdsTab
              control={control}
              mediaSpace={selectedMediaSpace as MediaSpace}
              isReadOnly={isReadOnly}
              readyAds={readyAds}
            />
          </div>
          <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
            {!isReadOnly && (
              <Button
                variant='text'
                color='error'
                aria-label='Close without saving'
                className='w-5/12 mr-3'
                onClick={onClose}
                data-test='closeWithoutSavingButton'
                loading={processing}
              >
                CLOSE WITHOUT SAVING
              </Button>
            )}
            <Button
              variant='contained'
              color='primary'
              size='large'
              type='submit'
              aria-label={isReadOnly ? 'Close' : 'Save ad set'}
              data-test='saveAdSetButton'
              onClick={isReadOnly ? onClose : undefined}
              loading={processing}
              disabled={!watch('bundleId')}
            >
              {isReadOnly ? 'CLOSE' : 'SAVE AD SET'}
            </Button>
          </Box>
        </form>
      </AdSetProvider>
    </Modal>
  );
};

type AdSetsTabValue = 'details' | 'ads';

export type AdSetModalSaveHandler = (updatedAdSet: AdSet) => Promise<void>;

interface AdSetModalProps {
  isOpen: boolean;
  isReadOnly: boolean;
  adSet: AdSet;
  onClose: () => void;
  saveHandler?: AdSetModalSaveHandler;
  retailerId: string | null;
  brandId: string | null;
  initialErrors?: Record<string, Array<string>>;
}
