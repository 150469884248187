import React, { useEffect, useMemo } from 'react';
import { Control, Controller, ControllerRenderProps, useWatch } from 'react-hook-form';
import { Autocomplete, Box, Stack, TextField } from '@zitcha/component-library';
import { AdSet, AdSetDiscount, useGetBundle, useGetBundles, useGetCalendarPeriods } from 'v2/lib/api/ad-management';
import { PlacementAutocomplete } from '../PlacementList/PlacementAutocomplete';
import { SkuCodes } from './SkuCodes';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { useCurrencyRender } from '../RenderCurrency/RenderCurrency';
import { MediaSpace, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { AdSetFormValues } from './AdSetModal';
import { DiscountSelector } from '../Discounts/DiscountSelector';
import { DiscountData } from '../Discounts/DiscountsModal';
import { calculateBudget } from 'v2/features/Planning/NewPlanAdSetsCellRenders';
import { CreateDiscountRequestType } from 'v2/lib/api/ad-management/model/createDiscountRequestType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export type DetailsTabProps = {
  control: Control<AdSetFormValues>;
  setValue: (name: string, value: unknown) => void;
  existingAdSet?: AdSet;
  retailerId: string;
  isReadOnly: boolean;
  setLoadingBundle?: (loading: boolean) => void;
  hadBundleError?: boolean;
};

export const DetailsTab: React.FC<DetailsTabProps> = ({
  control,
  setValue,
  existingAdSet,
  retailerId,
  isReadOnly,
  setLoadingBundle,
  hadBundleError,
}) => {
  const userOrganisation = useUserOrganisation();
  const isRetailer = userOrganisation.is_retailer;

  const bundleId = existingAdSet?.bundleIds?.[0] || '';
  const {
    data: existing_bundle,
    isPending,
    isSuccess: bundleFound,
  } = useGetBundle(bundleId, { query: { refetchOnMount: false } });
  const { getFormatted } = useCurrencyRender({ amount: undefined, organisationId: retailerId, showDecimals: true });

  const { data: mediaSpacesData, isLoading: isLoadingMediaSpaces } = useGetMediaSpaces({ organisation_id: retailerId });
  const mediaSpaces: Array<MediaSpace> = useMemo(() => mediaSpacesData?.data || [], [mediaSpacesData]);

  const { data: schedules, isLoading: isLoadingSchedules } = useGetCalendarPeriods({ organisation_id: retailerId });

  const [schedule, mediaSpace, placement, ads, rate, discount] = useWatch({
    control,
    name: ['schedule', 'mediaSpace', 'placement', 'ads', 'rate', 'discount'],
  });

  const { data: bundle, isPending: isGetBundlesPending } = useGetBundles(
    {
      organisation_id: retailerId,
      media_space_id: mediaSpace?.id,
      calendar_period_id: schedule?.id,
      location_id: placement?.id,
    },
    {
      query: {
        queryKey: ['bundles', retailerId, mediaSpace?.id, schedule?.id, placement?.id],
        enabled: !!(mediaSpace?.id && schedule?.id && placement?.id),
      },
    }
  );

  useEffect(() => {
    setValue('budget', calculateBudget(rate, discount));
  }, [rate, discount, setValue]);

  useEffect(() => {
    if (!isPending && !schedule && existing_bundle?.data?.calendarPeriod) {
      setValue('schedule', existing_bundle.data.calendarPeriod);
    }
    if (!isPending && !placement && existing_bundle?.data?.locations?.[0]) {
      setValue('placement', existing_bundle.data.locations[0]);
    }
    if (!isPending && !mediaSpace && existing_bundle?.data?.mediaSpace) {
      const preselectedMediaSpace = mediaSpaces.find((adType) => adType?.id === existing_bundle.data.mediaSpace.id);
      if (preselectedMediaSpace) {
        setValue('mediaSpace', preselectedMediaSpace);
      }
    }
    if (!isPending && !rate && existing_bundle?.data?.price) {
      setValue('rate', existing_bundle.data.price);
    }
  }, [existing_bundle, isPending, bundleFound, mediaSpaces, setValue]);

  useEffect(() => {
    const bundleData = bundle?.data?.[0];
    const price = Number(bundleData?.price ?? 0);
    const bundleId = bundleData?.id ?? null;

    setValue('rate', price);
    setValue('bundleId', bundleId);
    setLoadingBundle?.(isGetBundlesPending);
  }, [isGetBundlesPending, bundle, setValue, setLoadingBundle]);

  const updateDiscountData = (data: DiscountData) => {
    let updatedDiscount;
    if (!discount?.isAdSet) {
      updatedDiscount = { value: Number(data.discount), type: data.type } as AdSetDiscount;
    } else {
      updatedDiscount = { ...discount, value: Number(data.discount), type: data.type } as AdSetDiscount;
    }

    setValue('discount', updatedDiscount);
  };

  const showDiscountField = isRetailer || (discount && (discount.value > 0 || discount.type !== 'percentage'));

  return (
    <Stack direction='column' spacing={3} marginY={4}>
      <Controller
        name='schedule'
        control={control}
        render={({ field }: { field: ControllerRenderProps }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
          const { ref: _ref, ...rest } = { ...field };
          return (
            <Autocomplete
              {...rest}
              size='medium'
              fullWidth
              getOptionLabel={(data) =>
                `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
              }
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => {
                return <TextField {...params} variant='outlined' error={hadBundleError} label='Schedule' />;
              }}
              options={schedules?.data ?? []}
              loading={isLoadingSchedules}
              onChange={(_, newValue) => field.onChange(newValue)}
              disabled={isReadOnly}
            />
          );
        }}
      />
      <Controller
        name='mediaSpace'
        control={control}
        render={({ field }: { field: ControllerRenderProps }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
          const { ref, ...rest } = field;
          return (
            <Autocomplete
              {...rest}
              fullWidth
              getOptionLabel={(data) => data?.name}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' error={hadBundleError} label='Media type' data-no-cap />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              options={mediaSpaces}
              loading={isLoadingMediaSpaces}
              onChange={(_, newValue) => field.onChange(newValue)}
              disabled={isReadOnly}
            />
          );
        }}
      />
      <Controller
        name='placement'
        control={control}
        render={({ field }) => (
          <PlacementAutocomplete
            onChange={field.onChange}
            value={field.value}
            fullWidth={true}
            mediaSpaceId={mediaSpace?.id}
            retailerId={retailerId}
            disabled={isReadOnly}
            textFieldProps={{ variant: 'outlined', hasError: hadBundleError, label: 'Placement' }}
          />
        )}
      />
      <SkuCodes ads={ads} isReadOnly />
      <Box display='flex' gap={2} width='100%'>
        <Controller
          name='rate'
          control={control}
          render={({ field }) => <TextField value={getFormatted(field.value)} label='Rate' disabled fullWidth />}
        />
        {showDiscountField && (
          <Controller
            name='discount'
            control={control}
            render={({ field }) => (
              <DiscountSelector
                onChange={(data) => updateDiscountData(data)}
                variant='outlined'
                row={{
                  type: field.value?.type || CreateDiscountRequestType.percentage,
                  discount: String(field.value?.value || '0'),
                }}
                readOnly={isReadOnly || Boolean(hadBundleError) || !isRetailer}
                label='Discount'
                fullWidth
                helperText='If there is a higher discount applicable to this ad set, it will be applied instead.'
              />
            )}
          />
        )}

        <Controller
          name='budget'
          control={control}
          render={({ field }) => (
            <TextField value={getFormatted(field.value)} label='Budget' helperText='' disabled fullWidth />
          )}
        />
      </Box>
    </Stack>
  );
};
