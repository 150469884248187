import React from 'react';
import { Outlet } from 'react-router-dom';
import { BaseLayoutContent } from './BaseLayoutContent';
import { ContainerProps } from '@mui/material';

interface BaseLayoutProps {
  heading?: string;
  size?: ContainerProps['maxWidth'];
}

export const BaseLayout = ({ heading, size }: BaseLayoutProps) => {
  return (
    <BaseLayoutContent heading={heading} size={size}>
      <Outlet />
    </BaseLayoutContent>
  );
};
