/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Inventory API
 * Zitcha Inventory API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type {
  GetInventoryByMediaSpaceLocation200,
  GetInventoryReport200,
  GetInventoryReportParams,
  GetLocationTypesByMediaSpace200,
  GetLocationsByMediaSpace200,
  GetLocationsByMediaSpaceParams,
  GetMediaSpaces200,
  GetMediaSpacesByMediaType200,
  GetMediaSpacesByMediaTypeParams,
  GetMediaSpacesParams,
  GetMediaTypeById200,
  GetMediaTypes200,
  GetMediaTypesParams,
  GetRenderableByMediaType200,
} from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get all media types
 */
export const getMediaTypes = (
  params: GetMediaTypesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaTypes200>({ url: `/inventory/v1/media-types`, method: 'GET', params, signal }, options);
};

export const getGetMediaTypesQueryKey = (params: GetMediaTypesParams) => {
  return [`/inventory/v1/media-types`, ...(params ? [params] : [])] as const;
};

export const getGetMediaTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaTypes>>,
  TError = ErrorType<void>,
>(
  params: GetMediaTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypes>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaTypesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaTypes>>> = ({ signal }) =>
    getMediaTypes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaTypes>>>;
export type GetMediaTypesQueryError = ErrorType<void>;

/**
 * @summary Get all media types
 */
export const useGetMediaTypes = <TData = Awaited<ReturnType<typeof getMediaTypes>>, TError = ErrorType<void>>(
  params: GetMediaTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypes>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaTypesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a media type by ID
 */
export const getMediaTypeById = (
  mediaTypeId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaTypeById200>(
    { url: `/inventory/v1/media-types/${mediaTypeId}`, method: 'GET', signal },
    options
  );
};

export const getGetMediaTypeByIdQueryKey = (mediaTypeId: string) => {
  return [`/inventory/v1/media-types/${mediaTypeId}`] as const;
};

export const getGetMediaTypeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaTypeById>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypeById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaTypeByIdQueryKey(mediaTypeId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaTypeById>>> = ({ signal }) =>
    getMediaTypeById(mediaTypeId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaTypeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaTypeById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaTypeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaTypeById>>>;
export type GetMediaTypeByIdQueryError = ErrorType<void>;

/**
 * @summary Get a media type by ID
 */
export const useGetMediaTypeById = <TData = Awaited<ReturnType<typeof getMediaTypeById>>, TError = ErrorType<void>>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypeById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaTypeByIdQueryOptions(mediaTypeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all fields and their properties to render the field.
 */
export const getRenderableByMediaType = (
  mediaTypeId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetRenderableByMediaType200>(
    { url: `/inventory/v1/media-types/${mediaTypeId}/renderable`, method: 'GET', signal },
    options
  );
};

export const getGetRenderableByMediaTypeQueryKey = (mediaTypeId: string) => {
  return [`/inventory/v1/media-types/${mediaTypeId}/renderable`] as const;
};

export const getGetRenderableByMediaTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getRenderableByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRenderableByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRenderableByMediaTypeQueryKey(mediaTypeId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRenderableByMediaType>>> = ({ signal }) =>
    getRenderableByMediaType(mediaTypeId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaTypeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRenderableByMediaType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRenderableByMediaTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getRenderableByMediaType>>>;
export type GetRenderableByMediaTypeQueryError = ErrorType<void>;

/**
 * @summary Get all fields and their properties to render the field.
 */
export const useGetRenderableByMediaType = <
  TData = Awaited<ReturnType<typeof getRenderableByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRenderableByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRenderableByMediaTypeQueryOptions(mediaTypeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all media spaces of a media type
 */
export const getMediaSpacesByMediaType = (
  mediaTypeId: string,
  params?: GetMediaSpacesByMediaTypeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaSpacesByMediaType200>(
    { url: `/inventory/v1/media-types/${mediaTypeId}/media-spaces`, method: 'GET', params, signal },
    options
  );
};

export const getGetMediaSpacesByMediaTypeQueryKey = (mediaTypeId: string, params?: GetMediaSpacesByMediaTypeParams) => {
  return [`/inventory/v1/media-types/${mediaTypeId}/media-spaces`, ...(params ? [params] : [])] as const;
};

export const getGetMediaSpacesByMediaTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaSpacesByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  params?: GetMediaSpacesByMediaTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaSpacesByMediaTypeQueryKey(mediaTypeId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>> = ({ signal }) =>
    getMediaSpacesByMediaType(mediaTypeId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaTypeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaSpacesByMediaType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaSpacesByMediaTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>>;
export type GetMediaSpacesByMediaTypeQueryError = ErrorType<void>;

/**
 * @summary Get all media spaces of a media type
 */
export const useGetMediaSpacesByMediaType = <
  TData = Awaited<ReturnType<typeof getMediaSpacesByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  params?: GetMediaSpacesByMediaTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaSpacesByMediaTypeQueryOptions(mediaTypeId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all media spaces
 */
export const getMediaSpaces = (
  params: GetMediaSpacesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaSpaces200>(
    { url: `/inventory/v1/media-spaces`, method: 'GET', params, signal },
    options
  );
};

export const getGetMediaSpacesQueryKey = (params: GetMediaSpacesParams) => {
  return [`/inventory/v1/media-spaces`, ...(params ? [params] : [])] as const;
};

export const getGetMediaSpacesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaSpaces>>,
  TError = ErrorType<void>,
>(
  params: GetMediaSpacesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpaces>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaSpacesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaSpaces>>> = ({ signal }) =>
    getMediaSpaces(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaSpaces>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaSpacesQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaSpaces>>>;
export type GetMediaSpacesQueryError = ErrorType<void>;

/**
 * @summary Get all media spaces
 */
export const useGetMediaSpaces = <TData = Awaited<ReturnType<typeof getMediaSpaces>>, TError = ErrorType<void>>(
  params: GetMediaSpacesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpaces>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaSpacesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all location types of a media space
 */
export const getLocationTypesByMediaSpace = (
  mediaSpaceId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetLocationTypesByMediaSpace200>(
    { url: `/inventory/v1/media-spaces/${mediaSpaceId}/locationTypes`, method: 'GET', signal },
    options
  );
};

export const getGetLocationTypesByMediaSpaceQueryKey = (mediaSpaceId: string) => {
  return [`/inventory/v1/media-spaces/${mediaSpaceId}/locationTypes`] as const;
};

export const getGetLocationTypesByMediaSpaceQueryOptions = <
  TData = Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>,
  TError = ErrorType<void>,
>(
  mediaSpaceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLocationTypesByMediaSpaceQueryKey(mediaSpaceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>> = ({ signal }) =>
    getLocationTypesByMediaSpace(mediaSpaceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaSpaceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLocationTypesByMediaSpaceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>
>;
export type GetLocationTypesByMediaSpaceQueryError = ErrorType<void>;

/**
 * @summary Get all location types of a media space
 */
export const useGetLocationTypesByMediaSpace = <
  TData = Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>,
  TError = ErrorType<void>,
>(
  mediaSpaceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocationTypesByMediaSpace>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLocationTypesByMediaSpaceQueryOptions(mediaSpaceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all locations with inventory of a media space
 */
export const getLocationsByMediaSpace = (
  mediaSpaceId: string,
  params?: GetLocationsByMediaSpaceParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetLocationsByMediaSpace200>(
    { url: `/inventory/v1/media-spaces/${mediaSpaceId}/locations`, method: 'GET', params, signal },
    options
  );
};

export const getGetLocationsByMediaSpaceQueryKey = (mediaSpaceId: string, params?: GetLocationsByMediaSpaceParams) => {
  return [`/inventory/v1/media-spaces/${mediaSpaceId}/locations`, ...(params ? [params] : [])] as const;
};

export const getGetLocationsByMediaSpaceQueryOptions = <
  TData = Awaited<ReturnType<typeof getLocationsByMediaSpace>>,
  TError = ErrorType<void>,
>(
  mediaSpaceId: string,
  params?: GetLocationsByMediaSpaceParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocationsByMediaSpace>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLocationsByMediaSpaceQueryKey(mediaSpaceId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLocationsByMediaSpace>>> = ({ signal }) =>
    getLocationsByMediaSpace(mediaSpaceId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaSpaceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLocationsByMediaSpace>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLocationsByMediaSpaceQueryResult = NonNullable<Awaited<ReturnType<typeof getLocationsByMediaSpace>>>;
export type GetLocationsByMediaSpaceQueryError = ErrorType<void>;

/**
 * @summary Get all locations with inventory of a media space
 */
export const useGetLocationsByMediaSpace = <
  TData = Awaited<ReturnType<typeof getLocationsByMediaSpace>>,
  TError = ErrorType<void>,
>(
  mediaSpaceId: string,
  params?: GetLocationsByMediaSpaceParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocationsByMediaSpace>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLocationsByMediaSpaceQueryOptions(mediaSpaceId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the inventory of a MediaSpace location
 */
export const getInventoryByMediaSpaceLocation = (
  mediaSpaceId: string,
  locationId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetInventoryByMediaSpaceLocation200>(
    { url: `/inventory/v1/media-spaces/${mediaSpaceId}/locations/${locationId}`, method: 'GET', signal },
    options
  );
};

export const getGetInventoryByMediaSpaceLocationQueryKey = (mediaSpaceId: string, locationId: string) => {
  return [`/inventory/v1/media-spaces/${mediaSpaceId}/locations/${locationId}`] as const;
};

export const getGetInventoryByMediaSpaceLocationQueryOptions = <
  TData = Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>,
  TError = ErrorType<void>,
>(
  mediaSpaceId: string,
  locationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryByMediaSpaceLocationQueryKey(mediaSpaceId, locationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>> = ({ signal }) =>
    getInventoryByMediaSpaceLocation(mediaSpaceId, locationId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(mediaSpaceId && locationId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInventoryByMediaSpaceLocationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>
>;
export type GetInventoryByMediaSpaceLocationQueryError = ErrorType<void>;

/**
 * @summary Get the inventory of a MediaSpace location
 */
export const useGetInventoryByMediaSpaceLocation = <
  TData = Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>,
  TError = ErrorType<void>,
>(
  mediaSpaceId: string,
  locationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryByMediaSpaceLocation>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInventoryByMediaSpaceLocationQueryOptions(mediaSpaceId, locationId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get inventory report
 */
export const getInventoryReport = (
  params: GetInventoryReportParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetInventoryReport200>(
    { url: `/inventory/v1/reporting/inventory`, method: 'GET', params, signal },
    options
  );
};

export const getGetInventoryReportQueryKey = (params: GetInventoryReportParams) => {
  return [`/inventory/v1/reporting/inventory`, ...(params ? [params] : [])] as const;
};

export const getGetInventoryReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getInventoryReport>>,
  TError = ErrorType<void>,
>(
  params: GetInventoryReportParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryReport>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInventoryReport>>> = ({ signal }) =>
    getInventoryReport(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInventoryReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInventoryReportQueryResult = NonNullable<Awaited<ReturnType<typeof getInventoryReport>>>;
export type GetInventoryReportQueryError = ErrorType<void>;

/**
 * @summary Get inventory report
 */
export const useGetInventoryReport = <TData = Awaited<ReturnType<typeof getInventoryReport>>, TError = ErrorType<void>>(
  params: GetInventoryReportParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryReport>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInventoryReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
