import React from 'react';
import { Button, Box } from '@zitcha/component-library';
import { ErrorFeedback } from '../form_elements/Common';
import Errors from '../form_elements/Errors';

const ActionBar = ({ onCancel = () => {}, onSave = () => {}, onPreview = () => {}, errors = [] }) => {
  return (
    <Box
      component='section'
      sx={{
        border: '1px solid #ECECEC',
        borderRadius: '8px',
        backgroundColor: '#F2F2F2',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Button onClick={onCancel} variant='outlined' size='large' sx={{ minWidth: '150px' }}>
          Cancel
        </Button>
        <Button onClick={onPreview} variant='outlined' size='large' sx={{ minWidth: '150px' }}>
          Preview Listing
        </Button>
        <Button onClick={onSave} size='large' sx={{ minWidth: '150px' }}>
          Save
        </Button>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <ErrorFeedback type='invalid' isInvalid={errors.length > 0}>
          <Errors errors={errors} />
        </ErrorFeedback>
      </Box>
    </Box>
  );
};

export default ActionBar;
