import React, { SetStateAction } from 'react';
import { EditIcon, Modal } from '@zitcha/component-library';
import { ViewRole } from './ViewRole';
import { ModalFooter } from '../components/ModalFooter';
import { EditRole } from './EditRole';
import { useDeleteRoleWithHandler } from './hooks/useDeleteRoleWithHandler';
import { CreateRole } from './CreateRole';
import { useAddRoleWithHandler } from './hooks/useAddRoleWithHandler';
import { useUpdateRoleWithHandler } from './hooks/useUpdateRoleWithHandler';
import { SnackbarNotifications } from 'app/components/SnackNotifications/SnackbarNotifications';

export interface ModalState {
  open: boolean;
  mode: 'edit' | 'view';
  roleId: number | null;
  title: string;
  editableRole?: boolean;
}

type RoleManagementModalProps = {
  modalState: ModalState;
  handleClose: () => void;
  handleModalState?: React.Dispatch<SetStateAction<ModalState>>;
};

export const RoleManagementModal: React.FC<RoleManagementModalProps> = ({
  modalState,
  handleClose,
  handleModalState,
}) => {
  const roleId = modalState.roleId;
  const isCreateMode = modalState.mode === 'edit' && !roleId;
  const isEditMode = modalState.mode === 'edit' && roleId;
  const isViewMode = modalState.mode === 'view' && roleId;

  const {
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    isPending: isCreateLoading,
    error: createError,
    handleAddRole,
  } = useAddRoleWithHandler({ handleClose });

  const {
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdateLoading,
    error: updateError,
    handleUpdateRole,
  } = useUpdateRoleWithHandler({ handleClose });

  const {
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    isPending: isDeleteLoading,
    error: deleteError,
    handleDeleteRole,
  } = useDeleteRoleWithHandler({ roleId, handleClose });

  const renderViewMode = () => (
    <>
      <ViewRole roleId={Number(roleId)} />
      <ModalFooter
        primaryButton={{
          label: 'Edit role',
          icon: EditIcon,
          isPending: !modalState.editableRole,
          onClick: () => {
            handleModalState?.({ ...modalState, mode: 'edit', title: 'Edit role' });
          },
        }}
        secondaryButton={{
          label: 'Close',
          onClick: handleClose,
          variant: 'close',
        }}
      />
    </>
  );

  const renderEditMode = () => (
    <EditRole
      roleId={roleId}
      handleUpdateRole={handleUpdateRole}
      isPending={isUpdateLoading}
      secondaryButton={{
        label: 'Delete role',
        variant: 'warning',
        onClick: handleDeleteRole,
      }}
    />
  );

  const renderCreateMode = () => (
    <CreateRole
      handleCreateRole={handleAddRole}
      isPending={isCreateLoading}
      secondaryButton={{
        label: 'Cancel',
        variant: 'normal',
        onClick: handleClose,
      }}
    />
  );

  return (
    <>
      {isCreateMode && (
        <SnackbarNotifications
          isError={isCreateError}
          isSuccess={isCreateSuccess}
          isProcessing={isCreateLoading}
          messages={{
            error: createError?.response?.data?.error || 'Error adding new role. Please try again.',
            success: 'New role added',
          }}
        />
      )}
      {isEditMode && (
        <>
          <SnackbarNotifications
            isError={isDeleteError}
            isSuccess={isDeleteSuccess}
            isProcessing={isDeleteLoading}
            messages={{
              error: deleteError?.response?.data?.error || 'Error deleting role',
              success: 'Role deleted',
            }}
          />
          <SnackbarNotifications
            isError={isUpdateError}
            isSuccess={isUpdateSuccess}
            isProcessing={isUpdateLoading}
            messages={{
              error: updateError?.response?.data?.error || 'Error updating role',
              success: 'Role updated',
            }}
          />
        </>
      )}

      <Modal size='medium' open={modalState.open} onClose={handleClose} title={modalState.title}>
        <>
          {isViewMode && renderViewMode()}
          {isCreateMode && renderCreateMode()}
          {isEditMode && renderEditMode()}
        </>
      </Modal>
    </>
  );
};
