import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Box, Link, HomeIcon } from '@zitcha/component-library';

export interface BreadcrumbItem {
  path: string;
  title: string;
  active?: boolean;
}

interface CustomBreadcrumbsProps {
  items: Array<BreadcrumbItem>;
  showHomeIcon?: boolean;
}

export const CustomBreadcrumbs: React.FC<CustomBreadcrumbsProps> = ({ items, showHomeIcon = false }) => {
  const navigate = useNavigate();

  const breadcrumbLinks = items.map((item, index) => {
    const isLast = index === items.length - 1;

    return (
      <Link
        underline='hover'
        color='inherit'
        onClick={() => !isLast && navigate(item.path)}
        key={item.path}
        sx={{ cursor: isLast ? 'default' : 'pointer', display: 'flex', alignItems: 'center' }}
      >
        {item.title}
      </Link>
    );
  });

  return (
    <Box mb={2}>
      <Breadcrumbs ariaLabel='breadcrumbs'>
        {showHomeIcon && (
          <Link underline='hover' color='inherit' onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <Box display='flex' alignItems='center'>
              <HomeIcon />
            </Box>
          </Link>
        )}
        {breadcrumbLinks}
      </Breadcrumbs>
    </Box>
  );
};
