import { setupWorker } from 'msw/browser';

let handlers;
try {
  handlers = require('./msw.client.handlers').handlers;
} catch {
  /* eslint-disable no-console */
  console.log('handlers not found. Create a `msw.client.handlers` from `msw.client.handlers.sample`');
  handlers = [];
}

export const worker = setupWorker(...handlers);
