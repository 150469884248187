import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNetworkBranding from 'app/OrganisationSettings/useNetworkBranding';
import { clearSession, refreshSession, selectRefreshStatus, selectUser } from 'features/session/sessionSlice';
import AppLoading from 'v2/components/AppLoading';
import Chrome from 'v2/components/Navigation/Chrome';
import { Outlet } from 'react-router-dom';
import { CustomThemeProvider } from '@zitcha/component-library';
import { useGetFeatureFlags } from '../../lib/api/system-api';

// This component is use to load the user session data form the backend when they have not logged in
// or the data is not available in the redux store. This will also trigger a refetch of the feature flags
// and network branding data once the user has been logged into the application.
const ProtectedInner = () => {
  const [loading, setIsLoading] = useState(true);
  const [fetchingSession, setFetchingSession] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const refreshStatus = useSelector(selectRefreshStatus);
  const { isInitialLoading, data: networkBranding } = useNetworkBranding();
  const { isLoading: isFeatureFlagLoading } = useGetFeatureFlags();

  const isRetailer = user?.active_organisation?.is_retailer;

  const refresh = useCallback(async () => {
    try {
      const res = await dispatch(refreshSession());
      if (res && res.error) {
        dispatch(clearSession());
      }
    } catch (e) {
      dispatch(clearSession());
    } finally {
      setIsLoading(false);
      setFetchingSession(false);
    }
  }, [setIsLoading, dispatch, setFetchingSession]);

  useEffect(() => {
    if (fetchingSession) return;

    if (!user && refreshStatus === 'idle') {
      setFetchingSession(true);
      refresh();
    }

    if (user && !fetchingSession) {
      setIsLoading(false);
    }
  }, [user, fetchingSession, setFetchingSession, refresh, refreshStatus]);

  if (loading || fetchingSession || (isInitialLoading && isRetailer) || isFeatureFlagLoading) return <AppLoading />;

  return (
    <CustomThemeProvider themeOverrides={networkBranding?.data?.settings?.theme}>
      <Chrome>
        <Outlet />
      </Chrome>
    </CustomThemeProvider>
  );
};

export default ProtectedInner;
