import { useTheme, MenuIcon, MenuOpenIcon, Box, Typography, IconButton, AppBar } from '@zitcha/component-library';
import React, { useContext } from 'react';
import { NavigationContext } from './Chrome';
import { UserName } from './UserDropdownMenu';
import { useNavigate } from 'react-router-dom';
import useNetworkBranding from 'app/OrganisationSettings/useNetworkBranding';
import { isLightColor } from 'helpers/ColorHelpers';

interface NavBarProps {
  page: string;
}

function NavBar({ page }: NavBarProps) {
  const { drawerWidth, drawerSizes, toggleDrawerWidth, user } = useContext(NavigationContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: networkBranding } = useNetworkBranding({ shouldFetch: false });

  const networkBrandingContrastText = isLightColor(theme?.components?.MuiAppBar?.styleOverrides?.root?.backgroundColor)
    ? '#000'
    : '#fff';

  const logoUrl = networkBranding?.data?.settings?.network_logo?.url ?? '/Zitcha_Brandmark White.png';

  return (
    <AppBar
      isPrimaryNav
      sx={{
        position: 'fixed' /* AppBar `position` prop seems broken */,
        color: networkBrandingContrastText,
      }}
    >
      <Box
        component='nav'
        display='flex'
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          height: '4rem',
        }}
      >
        <Box
          component='div'
          display='flex'
          sx={{
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <IconButton
            data-testid='menu'
            aria-label='menu'
            onClick={toggleDrawerWidth}
            size='medium'
            sx={{
              color: networkBrandingContrastText,
            }}
          >
            {drawerWidth === drawerSizes.expanded ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Box>
            <img
              src={logoUrl}
              alt={user?.active_organisation?.name}
              style={{
                objectFit: 'contain',
                maxHeight: '24px',
                width: 'auto',
                maxWidth: '108.7px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/');
              }}
            />
          </Box>
          <Typography variant='body1'>{page}</Typography>
        </Box>
        <UserName networkBrandingContrastText={networkBrandingContrastText} />
      </Box>
    </AppBar>
  );
}

export { NavBar };
