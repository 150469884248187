import React, { useMemo, useState } from 'react';
import { Alert, Col, Form, InputGroup, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import MinimumReviewTime from '../../../features/organisation_settings/components/MinimumReviewTime';
import TimeSelect from '../../../features/organisation_settings/components/TimeSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { clearSession, refreshSession, selectSession, selectUser } from '../../../features/session/sessionSlice';
import { updateOrganisationSettings, uploadOrganisationLogo } from 'lib/api';
import styled from 'styled-components';
import Reporting from '../Reporting';
import RetailerList from '../RetailerList/RetailerList';
import { useRetailerSettings } from 'v2/lib/hooks/useRetailerSettings';
import { Box, Button, SaveIcon } from '@zitcha/component-library';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import { PageSection } from 'v2/Layouts/PageSection';
import { SettingsTableContainer } from '../components/SettingsTableContainer';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';
import { Typography } from '@mui/material';

const StyledImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: 100%;
    max-height: 320px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #b9b9c0;
  }

  ${(props) =>
    !props.logo &&
    `
    border: 2px dashed #ddd;
    height 40px;
    `};
`;

const percLoaded = (p) => parseInt((p.loaded / p.total) * 100);

const GeneralOrganisationSettings = () => {
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const scopedOrgs = useScopedOrganisations({}).organisations;
  const {
    data: organisations,
    isLoading: retailersLoading,
    error: retailersLoadingError,
  } = useRetailerSettings({
    ids: scopedOrgs?.map((org) => org.id),
  });

  const dispatch = useDispatch();
  const [submitErrors, setSubmitErrors] = useState({});
  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const currencyList = session?.currencies ?? [];
  const settings = organisation?.settings;
  const [msg, setMsg] = useState(null);
  const timezoneOptions = useMemo(() => {
    return session?.timezones && session.timezones.length > 0
      ? session.timezones.map((timezone) => {
          return { label: timezone, value: timezone };
        })
      : [];
  }, [session?.timezones]);
  const [selectedTimezone, setSelectedTimezone] = useState(
    organisation.settings?.timezone
      ? {
          label: organisation.settings.timezone,
          value: organisation.settings.timezone,
        }
      : {}
  );
  const currencyOptions = useMemo(() => {
    if (!currencyList || !currencyList?.length) {
      return [];
    }
    return currencyList?.map((currency) => ({ label: currency?.name, value: currency?.code }));
  }, [currencyList]);

  const [selectedCurrency, setSelectedCurrency] = useState(organisation.settings?.defaultCurrency ?? '');

  const isRetailer = user.active_organisation.is_retailer;
  const hasRetailers = !isRetailer && organisations?.length > 0;

  const [logoProg, setLogoProg] = useState(null);

  const methods = useForm({
    defaultValues: useMemo(() => {
      const def = {
        facebookDefaultPageID: settings?.facebookDefaultPageID,
        facebookPixelID: settings?.facebookPixelID,
        defaultLocations: settings?.defaultLocations,
        timezone: settings?.timezone,
        defaultCurrency: settings?.defaultCurrency,
        facebookDefaultAudiences: settings?.facebookDefaultAudiences,
        googleDefaultAudiences: settings?.googleDefaultAudiences,
        maxSpendPacingDifference: settings?.maxSpendPacingDifference,
        webadsAttributionEnabled: settings?.webadsAttributionEnabled,
        webadsAttributionWindow: settings?.webadsAttributionWindow ?? 1,
        webadsAttributionTracking: settings?.webadsAttributionTracking,
      };
      return def;
    }, [settings]),
    mode: 'onSubmit',
  });

  const { setError, handleSubmit, control, formState } = methods;

  const { touchedFields, isSubmitted, errors, isSubmitting } = formState;

  const { startProcess, finishProcess } = useProcessingContext();
  const onSubmit = async (data) => {
    const processId = startProcess('Saving settings');
    setMsg(null);

    const updatedData = {
      facebookDefaultPageID: data.facebookDefaultPageID,
      facebookPixelID: data.facebookPixelID,
      timezone: selectedTimezone?.value,
      defaultLocations: data.defaultLocations,
      defaultCurrency: selectedCurrency,
      facebookDefaultAudiences: data.facebookDefaultAudiences,
      googleDefaultAudiences: data.googleDefaultAudiences,
      maxSpendPacingDifference: data.maxSpendPacingDifference,
      webadsAttributionEnabled: data?.webadsAttributionEnabled,
      webadsAttributionWindow: data.webadsAttributionWindow,
      webadsAttributionTracking: data.webadsAttributionTracking,
    };
    const settings = { ...(organisation.settings || {}), ...updatedData };
    setOrganisation({
      ...organisation,
      settings,
    });

    try {
      const res = await updateOrganisationSettings(organisation.id, {
        settings,
      });
      setOrganisation(res.data.data);
      dispatch(refreshSession());
      finishProcess(processId, { success: true, message: 'Settings saved' });
    } catch (e) {
      if (e.response?.status === 403) {
        dispatch(clearSession());
        navigate('/login');
      } else {
        const errors = e?.response?.data?.errors;
        const keys = Object.keys(errors || {});
        if (!errors || !keys.length) {
          finishProcess(processId, { success: false, message: 'Could not save the settings, please try again' });
        } else {
          keys.forEach((errorKey) => {
            const key = errorKey.replace('settings.', '');
            const splitKey = key.split('.');
            if (splitKey.length === 1) {
              setError(splitKey[0], {
                type: 'manual',
                message: errors[`settings.${key}`][0],
              });
            }
          });
          finishProcess(processId, { success: false, message: 'Could not save the settings, please try again' });
        }
      }
    }
  };

  const handleSettingChange = (setting, value) => {
    const settings = { ...(organisation.settings || {}), [setting]: value };
    setOrganisation({
      ...organisation,
      settings,
    });

    updateOrganisationSettings(organisation.id, {
      settings,
    })
      .then((resp) => {
        setOrganisation(resp.data.data);
        setSubmitErrors({});
        dispatch(refreshSession());
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch(clearSession());
          navigate('/login');
        } else if (err.response.status === 422) {
          setSubmitErrors(err.response.data.errors);
        }
      });
  };

  const selectedCurrencyLocked = useMemo(() => {
    if (organisation.settings?.defaultCurrency?.length > 0) {
      return true;
    }
    if (msg?.type === 'success' && selectedCurrency?.length > 0) {
      return true;
    }

    return false;
  }, [selectedCurrency, msg, organisation.settings?.defaultCurrency]);

  return (
    <Box pt={2.5}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PageSection id='my-organisation-settings-basic-information-section' heading='Basic information' marginTop={0}>
          <SettingsTableContainer>
            <Typography variant='h5'>{organisation?.name}</Typography>
            <Box backgroundColor='lightGray' maxWidth={350}>
              <StyledImage logo={organisation.settings?.logo?.url}>
                {organisation.settings?.logo?.url ? (
                  <img src={organisation.settings?.logo?.url} alt='' />
                ) : (
                  <p>Your logo here</p>
                )}
              </StyledImage>
            </Box>
            <Col lg={3} className='pt-2'>
              <Form.Group className='mb-0'>
                <Form.File
                  id='logo'
                  label={organisation.settings?.logo?.name || 'Select an image'}
                  errors={errors['logo']}
                  onChange={(e) => {
                    uploadOrganisationLogo(organisation.id, e.target.files[0], {
                      onUploadProgress: (p) => setLogoProg(percLoaded(p)),
                    }).then((resp) => {
                      setOrganisation(resp.data.data);
                      setLogoProg(null);
                      dispatch(refreshSession());
                    });
                  }}
                  custom
                />
                {logoProg !== null && <ProgressBar className='mt-2' animated now={logoProg} />}
              </Form.Group>
            </Col>
            {isRetailer && (
              <>
                <Row>
                  <Col md={6} lg={4}>
                    <Form.Group>
                      <Form.Label className='font-weight-bold text-dark'>Business Hours</Form.Label>
                      <p className='text-muted'>Set your business’ regular opening and closing hours.</p>
                      <div className='d-flex align-items-center'>
                        <TimeSelect
                          controlId='openingTime'
                          value={organisation.settings?.openingTime}
                          onChange={handleSettingChange}
                          errors={submitErrors['settings.openingTime']}
                        />
                        <span className='p-2 mb-3'>-</span>
                        <TimeSelect
                          controlId='closingTime'
                          value={organisation.settings?.closingTime}
                          onChange={handleSettingChange}
                          errors={submitErrors['settings.closingTime']}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group>
                      <Form.Label className='font-weight-bold text-dark'>Minimum Review Time (Hours)</Form.Label>
                      <p className='text-muted'>
                        Adequate time for you to review the orders before they go live. (In Hours)
                      </p>
                      <MinimumReviewTime
                        controlId='minimumReviewTime'
                        required={false}
                        value={organisation.settings?.minimumReviewTime}
                        onChange={handleSettingChange}
                        errors={submitErrors['settings.minimumReviewTime']}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group>
                      <Form.Label className='font-weight-bold text-dark'>Time zone</Form.Label>
                      <p className='text-muted'>Select your Timezone.</p>
                      <Select
                        className='pt-4'
                        placeholder='Select Timezone'
                        value={selectedTimezone}
                        onChange={(e) => {
                          setSelectedTimezone(e);
                          handleSettingChange('timezone', e.value);
                        }}
                        options={timezoneOptions}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group>
                      <Form.Label className='font-weight-bold text-dark'>
                        Max Spend Pacing Difference (2-20 %)
                      </Form.Label>
                      <InputGroup>
                        <Controller
                          name='maxSpendPacingDifference'
                          control={control}
                          render={({ field }) => (
                            <Form.Control
                              type='number'
                              max={20}
                              min={2}
                              aria-label='max-spend-pacing-difference-id'
                              style={session?.theme?.components?.form_control}
                              {...field}
                              isInvalid={
                                (isSubmitted || touchedFields?.maxSpendPacingDifference) &&
                                errors?.maxSpendPacingDifference?.message
                              }
                            />
                          )}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group>
                      <Form.Label className='font-weight-bold text-dark'>
                        Currency
                        {selectedCurrencyLocked && (
                          <FontAwesomeIcon icon={faLock} color='#007bff' title='Locked' size='sm' className='ml-1' />
                        )}
                      </Form.Label>
                      {selectedCurrencyLocked ? (
                        <Form.Control type='text' disabled={true} value={selectedCurrency} />
                      ) : (
                        <Form.Control
                          as='select'
                          disabled={selectedCurrencyLocked}
                          placeholder='Select Currency'
                          value={selectedCurrency}
                          onChange={(e) => setSelectedCurrency(e?.target?.value)}
                        >
                          <option hidden value='' />
                          {currencyOptions?.map((currency) => (
                            <option key={currency.value} value={currency?.value}>
                              {currency?.label}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </SettingsTableContainer>
        </PageSection>
        <PageSection id='my-organisation-settings-extension-channels-section' heading='Extension channels'>
          <SettingsTableContainer>
            <Row className='mb-2'>
              <Col lg='6'>
                <Form.Group>
                  <Form.Label>Facebook page id (only required for Retailers)</Form.Label>
                  <InputGroup>
                    <Controller
                      name='facebookDefaultPageID'
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type='text'
                          aria-label='facebook-default-page-id'
                          style={session?.theme?.components?.form_control}
                          {...field}
                          isInvalid={
                            (isSubmitted || touchedFields?.facebookDefaultPageID) &&
                            errors?.facebookDefaultPageID?.message
                          }
                        />
                      )}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col lg='6'>
                <Form.Group>
                  <Form.Label>Facebook Pixel</Form.Label>
                  <InputGroup>
                    <Controller
                      name='facebookPixelID'
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type='text'
                          aria-label='facebook-pixel-id'
                          style={session?.theme?.components?.form_control}
                          {...field}
                          isInvalid={
                            (isSubmitted || touchedFields?.facebookPixelID) && errors?.facebookPixelID?.message
                          }
                        />
                      )}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col lg='6'>
                <Form.Group>
                  <InputGroup>
                    <Controller
                      name='webadsAttributionEnabled'
                      control={control}
                      render={({ field }) => (
                        <div className='custom-control custom-switch'>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id='webadsAttributionEnabled'
                            {...field}
                            checked={field?.value === true}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                            }}
                          />
                          <label className='custom-control-label' htmlFor='webadsAttributionEnabled'>
                            Enable Conversion attribution for web ads
                          </label>
                        </div>
                      )}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col lg='6'>
                <Form.Group>
                  <Form.Label>Conversion attribution for web ads window size (In days) 1-7 only</Form.Label>
                  <InputGroup>
                    <Controller
                      name='webadsAttributionWindow'
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type='number'
                          min={1}
                          max={7}
                          aria-label='webads-attribution-window'
                          style={session?.theme?.components?.form_control}
                          {...field}
                          isInvalid={
                            (isSubmitted || touchedFields?.webadsAttributionWindow) &&
                            errors?.webadsAttributionWindow?.message
                          }
                        />
                      )}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col lg='6'>
                <Form.Group>
                  <Form.Label>Conversion attribution for web ads ad click tracking</Form.Label>
                  <Controller
                    name='webadsAttributionTracking'
                    control={control}
                    render={({ field }) => (
                      <div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            {...field}
                            id='webadsAttributionTrackingAll'
                            type='radio'
                            name='webadsAttributionTracking'
                            value='all'
                            checked={field?.value === 'all'}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <label className='form-check-label' htmlFor='webadsAttributionTrackingAll'>
                            All Ad clicks
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            {...field}
                            id='webadsAttributionTrackingLast'
                            type='radio'
                            name='webadsAttributionTracking'
                            value='last'
                            checked={field?.value === 'last'}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <label className='form-check-label' htmlFor='webadsAttributionTrackingLast'>
                            Last Ad click
                          </label>
                        </div>
                      </div>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </SettingsTableContainer>
        </PageSection>
        <PageSection id='my-organisation-settings-inventory-performance-section' heading='Inventory Performance'>
          <SettingsTableContainer>
            <Row>
              <Col>
                <h5>Inventory Performance Ranges</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Utilisation</h5>
              </Col>
              <Col>
                <h5>Revenue</h5>
              </Col>
            </Row>
          </SettingsTableContainer>
        </PageSection>
        <PageSection
          id='my-organisation-settings-mpa-section'
          heading='Meta MPA settings'
          hideSection={!(hasRetailers && organisations.some((retailer) => retailer?.facebook_mpa_enabled))}
        >
          <SettingsTableContainer>
            <RetailerList retailers={organisations?.filter((retailer) => retailer?.facebook_mpa_enabled)} />
          </SettingsTableContainer>
        </PageSection>
        <PageSection id='my-organisation-settings-reporting-section' heading='Reporting' hideSection={!isRetailer}>
          <SettingsTableContainer>
            <Reporting />
          </SettingsTableContainer>
        </PageSection>
        {retailersLoading && (
          <Row style={{ margin: '50px' }} className='mb-3 text-center d-flex align-items-center justify-content-center'>
            <Col>
              <Spinner animation='border' />
              &nbsp;
            </Col>
          </Row>
        )}
        {retailersLoadingError && (
          <Row className='mb-3'>
            <Col>
              <Alert variant='danger'>Could not load retailers</Alert>
            </Col>
          </Row>
        )}
        {msg && (
          <Alert className='mt-3' variant={msg.type}>
            {msg.body}
          </Alert>
        )}
        <Box my={3} display='flex' justifyContent='flex-end' width='100%'>
          <div>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              loading={isSubmitting || !!Object.keys(errors).length}
              startIcon={<SaveIcon />}
            >
              Save Changes
            </Button>
          </div>
        </Box>
      </Form>
    </Box>
  );
};

export default GeneralOrganisationSettings;
