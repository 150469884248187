import React from 'react';
import propTypes from 'prop-types';
import WalletStyledSpinner from './WalletStyledSpinner';
import { useFormState } from 'react-hook-form';
import { Button } from '@zitcha/component-library';

const WalletFormActionButton = ({ text, handleSubmit, loading = false }) => {
  const { errors } = useFormState();

  return (
    <Button type='submit' disabled={loading || !!Object.keys(errors).length} onClick={handleSubmit}>
      {text}
      {loading && <WalletStyledSpinner animation='border' variant='light' size='md' />}
    </Button>
  );
};

WalletFormActionButton.propTypes = {
  text: propTypes.string.isRequired,
  handleSubmit: propTypes.func.isRequired,
  loading: propTypes.bool,
};

export default WalletFormActionButton;
