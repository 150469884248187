import React, { useCallback } from 'react';
import { ListItem } from '@mui/material';
import {
  useTheme,
  TextField,
  InputAdornment,
  Box,
  Typography,
  SearchIcon,
  Modal,
  List,
} from '@zitcha/component-library';
import { ResponseScopedOrganisation } from 'v2/lib/api/access-management';
interface OrgTileProps {
  organisation: ResponseScopedOrganisation;
  onClick: (id: string) => void;
}

const OrgTile = ({ organisation, onClick }: OrgTileProps) => {
  const theme = useTheme();
  const handleClick = useCallback(() => {
    if (!organisation?.id) return;
    onClick(organisation?.id);
  }, [organisation, onClick]);

  return (
    <ListItem
      alignItems='flex-start'
      sx={{
        cursor: 'pointer',
        padding: '1rem',
        borderRadius: '0.5rem',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      }}
      key={organisation.id}
      onClick={handleClick}
    >
      <Typography variant='body1'>{organisation.name}</Typography>
    </ListItem>
  );
};

export const RetailerSelectionModal = ({
  isOpen,
  heading,
  onClose,
  onClick,
  retailers,
}: OrganisationSelectionModalProps) => {
  const SEARCH_FIELD_THRESHOLD = 5;
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredOrganisations = React.useMemo(() => {
    return retailers?.filter((organisation) => organisation?.name?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [retailers, searchTerm]);
  return (
    <Modal open={isOpen} onClose={onClose} size='small' title={heading}>
      <Box>
        {retailers.length >= SEARCH_FIELD_THRESHOLD && (
          <TextField
            id='search-organisation'
            value={searchTerm}
            type='search'
            placeholder='Search Organisation'
            onChange={(e) => setSearchTerm(e.target.value)}
            variant='outlined'
            fullWidth
            sx={{
              marginTop: '1rem',
              marginBottom: '1.5rem',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        <List
          component='ul'
          disablePadding
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginTop: '1rem',
            maxHeight: '25rem',
            overflowY: 'auto',
          }}
        >
          {filteredOrganisations.map((organisation) => (
            <OrgTile key={organisation.id} organisation={organisation} onClick={onClick} />
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export type OrganisationSelectionModalOnClickHandler = (id: string) => void;

interface OrganisationSelectionModalProps {
  isOpen: boolean;
  heading: string;
  onClose: () => void;
  onClick: OrganisationSelectionModalOnClickHandler;
  retailers: Array<ResponseScopedOrganisation>;
}
