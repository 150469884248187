import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/session/sessionSlice';
import { MANAGE_ORGANISATION_SETTINGS, ROLE_VIEW, TEAM_VIEW, USER_MANAGEMENT } from 'lib/permissions';
import { NetworkBranding } from './NetworkBranding/NetworkBranding';
import { TeamManagement } from './TeamManagement/TeamManagement';
import { RoleManagement } from './RoleManagement/RoleManagement';
import { UserManagement } from './UserManagement/UserManagement';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import GeneralOrganisationSettings from './GeneralOrganisationSettings/GeneralOrganisationSettings';
import {
  Tabs,
  Tab,
  DisplaySettingsIcon,
  PeopleAltIcon,
  WorkIcon,
  GroupsIcon,
  BrushIcon,
} from '@zitcha/component-library';
import { useSearchParams } from 'react-router-dom';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

type TabValue = 'global-settings' | 'user-management' | 'team-management' | 'role-management' | 'network-branding';

interface TabConfig {
  key: TabValue;
  name: string;
  condition: boolean;
  component: React.ReactNode;
  icon: React.ReactNode;
}

export const OrganisationSettings = () => {
  const { hasPermission, hasAllPermissions } = usePermissions();
  const user = useSelector(selectUser);
  const organisation = user.active_organisation;
  const isRetailer = organisation.is_retailer;

  const [searchParams, setSearchParams] = useSearchParams();

  const canManageOrgSettings = hasPermission(MANAGE_ORGANISATION_SETTINGS);
  const canManageUsers = hasAllPermissions([USER_MANAGEMENT, MANAGE_ORGANISATION_SETTINGS]);
  const canViewTeams = hasPermission(TEAM_VIEW);
  const canViewRoles = hasPermission(ROLE_VIEW);

  const allTabConfigs: Array<TabConfig> = [
    {
      key: 'global-settings',
      name: 'General',
      condition: canManageOrgSettings,
      component: <GeneralOrganisationSettings />,
      icon: <DisplaySettingsIcon />,
    },
    {
      key: 'user-management',
      name: 'Users',
      condition: canManageUsers,
      component: <UserManagement />,
      icon: <PeopleAltIcon />,
    },
    {
      key: 'team-management',
      name: 'Teams',
      condition: canViewTeams,
      component: <TeamManagement />,
      icon: <WorkIcon />,
    },
    {
      key: 'role-management',
      name: 'Roles',
      condition: canViewRoles,
      component: <RoleManagement />,
      icon: <GroupsIcon />,
    },
    {
      key: 'network-branding',
      name: 'Network branding',
      condition: isRetailer,
      component: <NetworkBranding />,
      icon: <BrushIcon />,
    },
  ];

  const tabMenuConfig: Array<TabConfig> = allTabConfigs.filter((tab) => tab.condition);

  useEffect(() => {
    const currentTab = searchParams.get('tab') as TabValue | null;
    if (!currentTab || !tabMenuConfig.some((tab) => tab.key === currentTab)) {
      setSearchParams((prev) => {
        prev.set('tab', tabMenuConfig[0]?.key || 'global-settings');
        return prev;
      });
    }
  }, [searchParams, setSearchParams, tabMenuConfig]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: TabValue) => {
    setSearchParams((prev) => {
      prev.set('tab', newValue);
      return prev;
    });
  };

  const currentTab = (searchParams.get('tab') as TabValue) || tabMenuConfig[0]?.key || 'global-settings';

  return (
    <BaseLayoutWithChildren heading='Organisation settings' size='lg'>
      <PageSection id='organisation-settings-tabs'>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label='Organisation Settings Tabs'>
          {tabMenuConfig.map((tab) => (
            <Tab
              key={tab.key}
              label={tab.name}
              value={tab.key}
              iconPosition='start'
              icon={tab.icon}
              sx={{
                minHeight: '35px',
              }}
            />
          ))}
        </Tabs>
      </PageSection>

      <PageSection id='organisation-settings-content'>
        {tabMenuConfig.find((tab) => tab.key === currentTab)?.component}
      </PageSection>
    </BaseLayoutWithChildren>
  );
};
