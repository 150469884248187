import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, CircularProgress, Divider, SaveIcon } from '@zitcha/component-library';
import { uploadNetworkBrandingLogo } from 'v2/lib/api/system-management';
import { toast } from 'react-toastify';
import { useNetworkBrandingForm } from './useNetworkBrandingForm';
import { ColorPickerField } from './ColorPickerField';
import { FileUpload } from './FileUpload';
import { FormValues } from './types';
import { GenericTabHeader } from '../components/GenericTabHeader';
import { SettingsTableContainer } from '../components/SettingsTableContainer';
import { LoadingIndicator } from '../../../v2/components/PlacementList/components/LoadingIndicator';

const MAX_FILE_SIZE = 5242880;

const NetworkBrandingContent = ({
  settings,
  defaultValues,
  handleSave,
  setUploadProgress,
  handleLogoUpload,
  handleLogoReset,
  uploadProgress,
  mutation,
}: WithNetworkBrandingProps) => {
  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      networkLogo: settings?.network_logo ?? defaultValues.networkLogo,
      navbarBg: settings?.theme?.components?.MuiAppBar?.styleOverrides?.root?.backgroundColor ?? defaultValues.navbarBg,
      primaryColor: settings?.theme?.palette?.primary?.main ?? defaultValues.primaryColor,
    },
  });

  const networkLogo = watch('networkLogo');
  const HAS_NETWORK_LOGO = !!(networkLogo && networkLogo.url !== defaultValues?.networkLogo?.url);

  const onSubmit = (data: FormValues) => {
    void handleSave(data);
  };

  const resetFormValues = (values: Partial<FormValues>) => {
    Object.entries(values).forEach(([key, value]) => {
      setValue(key as keyof FormValues, value);
    });
  };

  return (
    <Box pt={2.5}>
      <GenericTabHeader
        title='Network branding'
        subtitle='Suppliers will see this branding on the navigation bar when they use the platform.'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SettingsTableContainer>
          <Controller
            name='networkLogo'
            control={control}
            render={({ field }) => (
              <FileUpload
                label='Network logo'
                subLabel='Network logo is mandatory before selecting branding colors.'
                value={field.value}
                backgroundColor={watch('navbarBg')}
                onChange={(file) => {
                  if (file && file.size > MAX_FILE_SIZE) {
                    toast.error('The file must be under 5MB.');
                    return;
                  }
                  uploadNetworkBrandingLogo(
                    { network_logo: file },
                    {
                      onUploadProgress: (p) => setUploadProgress((p.loaded / (p.total ?? 1)) * 100),
                    }
                  )
                    .then((resp) => {
                      handleLogoUpload(resp);
                      field.onChange(resp.network_logo);
                    })
                    .catch(() => {
                      toast.error('Error uploading network logo');
                      setUploadProgress(0);
                    });
                }}
                onReset={() => {
                  handleLogoReset(resetFormValues);
                }}
                hasLogo={HAS_NETWORK_LOGO}
                uploadProgress={uploadProgress}
              />
            )}
          />
          <Divider sx={{ marginBottom: 2 }} />
          <Controller
            name='navbarBg'
            control={control}
            render={({ field }) => (
              <ColorPickerField
                label='Navigation bar color'
                color={field.value.toUpperCase()}
                onChange={(v) => field.onChange(v.toUpperCase())}
                disabled={!HAS_NETWORK_LOGO}
                tooltip='You must upload a network logo before you can select network branding colors.'
              />
            )}
          />

          <Controller
            name='primaryColor'
            control={control}
            render={({ field }) => (
              <ColorPickerField
                label='Primary color'
                color={field.value.toUpperCase()}
                onChange={(v) => field.onChange(v.toUpperCase())}
                disabled={!HAS_NETWORK_LOGO}
                tooltip='This color will be used as the primary color throughout the application.'
              />
            )}
          />
        </SettingsTableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={mutation.isPending}
            startIcon={mutation.isPending ? <CircularProgress size={20} color='inherit' /> : <SaveIcon />}
          >
            Save Changes
          </Button>
        </Box>
      </form>
    </Box>
  );
};

type WithNetworkBrandingProps = Omit<ReturnType<typeof useNetworkBrandingForm>, 'isLoading'>;

/**
 * Loads network branding and display a loading if the data is not ready or display the component
 */
const withNetworkBranding = <T extends WithNetworkBrandingProps = WithNetworkBrandingProps>(
  Component: React.ComponentType<T>
) => {
  const displayName = Component.displayName || Component.name || 'Component';

  const ComponentWithNetworkBranding = (props: Omit<T, keyof WithNetworkBrandingProps>) => {
    const { isLoading, ...networkBrandingProps } = useNetworkBrandingForm();

    if (isLoading) {
      return <LoadingIndicator />;
    }

    return <Component {...networkBrandingProps} {...(props as T)} />;
  };

  ComponentWithNetworkBranding.displayName = `withNetworkBranding(${displayName})`;

  return ComponentWithNetworkBranding;
};

const NetworkBranding = withNetworkBranding(NetworkBrandingContent);

export { NetworkBranding };
