import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, SearchIcon } from '@zitcha/component-library';
import { RequestSearchTeams } from 'v2/lib/api/access-management';
import { TextFieldDebounced } from 'v2/components/TextFieldDebounced/TextFieldDebounced';
import { ProductSetAutocomplete } from '../components/ProductSetAutocomplete';
import { SelectedOptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { OrganisationsAutocomplete } from '../components/OrganisationsAutocomplete';
import { ProductSet } from 'features/common/types';

interface TeamManagementTableFiltersProps {
  onFilterChange: (newFilter: Partial<RequestSearchTeams>) => void;
}

export const TeamManagementTableFilters: React.FC<TeamManagementTableFiltersProps> = ({ onFilterChange }) => {
  const [selectedBrands, setSelectedBrands] = useState<SelectedOptionsType>([]);
  const [selectedProductSets, setSelectedProductSets] = useState<SelectedOptionsType>([]);

  useEffect(() => {
    onFilterChange({
      organisationIds: Array.isArray(selectedBrands)
        ? (selectedBrands?.map((brand) => brand.id) as unknown as Array<string>)
        : undefined,
      productSetIds: Array.isArray(selectedProductSets)
        ? selectedProductSets?.map((productSet: ProductSet) => productSet.id)
        : undefined,
    });
  }, [selectedBrands, selectedProductSets]);

  return (
    <Box sx={{ display: 'flex', padding: 2, justifyContent: 'space-between', gap: 2, flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <OrganisationsAutocomplete
          type='filter'
          autoCompletePropsOverride={{
            sx: { minWidth: 276 },
          }}
          selectedOptions={selectedBrands}
          setSelectedOptions={(data) => setSelectedBrands(data)}
        />
        <ProductSetAutocomplete
          type='filter'
          autoCompletePropsOverride={{
            sx: { minWidth: 276 },
          }}
          selectedOptions={selectedProductSets}
          setSelectedOptions={(data) => setSelectedProductSets(data)}
        />
      </Box>
      <TextFieldDebounced
        label='Search'
        id='outlined-start-adornment'
        sx={{ minWidth: 276 }}
        InputProps={{
          placeholder: 'Search by team name',
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(value) => onFilterChange({ q: String(value) })}
        debounceMs={500}
      />
    </Box>
  );
};
