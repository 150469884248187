import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@zitcha/component-library';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LinearProgress from '@mui/material/LinearProgress';
import { FilePreviews } from './FilePreviews';
import { Asset } from 'v2/lib/api/ad-management';
import { useUploadAsset } from 'v2/lib/api/ad-management';

interface DragAndDropUploaderProps {
  onAssetChange: (assets: Array<Asset>) => void;
  assets: Array<Asset>;
  minFiles?: number;
  maxFiles?: number;
  fileTypes?: Array<string>;
  isReadOnly: boolean;
  maxSize?: number;
}

export const DragAndDropUploader: React.FC<DragAndDropUploaderProps> = ({
  onAssetChange,
  assets,
  minFiles = 0,
  maxFiles = Infinity,
  fileTypes = ['image/*', 'video/*'],
  isReadOnly,
  maxSize = 100 * 1024 * 1024, // 100 MB
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const theme = useTheme();
  const { mutateAsync: postAsset, isPending: isUploading } = useUploadAsset();

  const onDragEnter = () => !isReadOnly && setIsDragging(true);
  const onDragLeave = () => !isReadOnly && setIsDragging(false);
  const onDrop = () => !isReadOnly && setIsDragging(false);

  const onFileDrop = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return;
    if (event.target.files === null) return;
    const newFile = event.target.files[0];
    if (newFile) {
      if (assets.length >= maxFiles) {
        alert(`Cannot upload more than ${maxFiles} files`);
        return;
      }
      const anyFileTypeAllowed = fileTypes.includes('any');
      const fileTypeIsValid = anyFileTypeAllowed || fileTypes.some((type) => newFile.type.match(type));
      if (!fileTypeIsValid) {
        alert('File type not allowed');
        return;
      }
      if (newFile.size > maxSize) {
        alert('File size exceeds ' + maxSize / 1024 / 1024 + ' MB');
        return;
      }

      try {
        const { data: uploadedAsset } = await postAsset({ data: { file: newFile } });
        onAssetChange([...assets, uploadedAsset]);
      } catch (error) {
        alert('Failed to upload file. Please try again.');
      }
    }
  };

  const deleteAsset = (assetToDelete: Asset) => {
    if (isReadOnly) return;
    const updatedAssets = assets.filter((asset) => asset.id !== assetToDelete.id);
    onAssetChange(updatedAssets);
  };

  return (
    <>
      {assets.length < maxFiles && !isReadOnly && (
        <>
          <div
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{
              border: '2px dashed',
              borderColor: `${isDragging ? theme.palette.primary.main : '#ccc'}`,
              borderRadius: '5px',
              padding: '20px',
              textAlign: 'center',
              cursor: isReadOnly ? 'default' : 'pointer',
              position: 'relative',
            }}
          >
            <Box display='flex' justifyContent='center' flexDirection='column' gap={2} alignItems='center'>
              <UploadFileIcon sx={{ color: theme.palette.primary.main }} />
              <Box id='this one' display='flex' flexDirection='column' alignItems='center'>
                <Box sx={{ display: 'flex', gap: '.5em' }}>
                  <Typography variant='inherit' sx={{ color: theme.palette.primary.main, textDecoration: 'underline' }}>
                    Click to upload
                  </Typography>
                  or drag and drop
                </Box>
                <Typography
                  variant='inherit'
                  sx={{
                    color: theme.palette.text.secondary,
                    marginTop: '.5em',
                    fontSize: '14px',
                  }}
                >
                  {`Allowed file types: ${fileTypes.join(', ')} | Min files: ${minFiles} | Max files: ${maxFiles}`}
                </Typography>
              </Box>
            </Box>
            <input
              data-testid='fileInput'
              type='file'
              value=''
              onChange={onFileDrop}
              accept={fileTypes.join(',')}
              style={{
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: isReadOnly ? 'default' : 'pointer',
              }}
              disabled={isReadOnly}
            />
          </div>
          <Box
            sx={{
              height: '2px',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            {isUploading ? <LinearProgress sx={{ color: theme.palette.primary.main }} /> : null}
          </Box>
        </>
      )}
      {assets.length > 0 ? <FilePreviews assets={assets} deleteAsset={deleteAsset} isReadOnly={isReadOnly} /> : null}
    </>
  );
};
