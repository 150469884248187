import React from 'react';
import { InventoryTable } from './InventoryTable';
import { Typography, Box, Button, AddIcon } from '@zitcha/component-library';
import NewAdSetModalWrapper from './NewAdSetModalWrapper';

export const InventoryPage = () => {
  const [isNewAdSetModalOpen, setIsNewAdSetModalOpen] = React.useState(false);
  return (
    <>
      <Box display='flex' gap={1.5} flexDirection='column' mb={3} mt={3}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'>Bookings overview</Typography>
          <Button
            endIcon={<AddIcon />}
            variant='contained'
            color='primary'
            onClick={() => {
              setIsNewAdSetModalOpen(true);
            }}
          >
            New ad set
          </Button>
        </Box>
      </Box>
      <InventoryTable />
      <NewAdSetModalWrapper isOpen={isNewAdSetModalOpen} onClose={() => setIsNewAdSetModalOpen(false)} />
    </>
  );
};
