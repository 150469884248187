import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import OrderPagination from '../features/orders/components/OrderPagination';
import Orders from '../features/orders/components/Orders';
import Filters from '../features/orders/components/Filters';
import useOrdersLogic from '../features/orders/hooks/useOrdersLogic';
import { PageSection } from 'v2/Layouts/PageSection';

const ArchivedOrders = () => {
  const listTopRef = useRef(null);
  const {
    data: orders,
    error,
    isLoading,
    handleClick,
    searchParams,
    setSearchParams,
  } = useOrdersLogic({
    archived: true,
  });

  return (
    <PageSection id='order-list-section'>
      <Container ref={listTopRef}>
        <Row>
          <Col>
            <Filters onChange={setSearchParams} filterFields={searchParams} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Orders data={orders?.data.data} isLoading={isLoading} error={error} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <OrderPagination
              totalItems={orders?.data.total}
              currentPage={orders?.data.current_page || 1}
              itemsPerPage={orders?.data.per_page || 20}
              onClick={handleClick}
              disabled={isLoading}
              ref={listTopRef}
            />
          </Col>
        </Row>
      </Container>
    </PageSection>
  );
};

export default ArchivedOrders;
