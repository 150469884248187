import React from 'react';
import { BaseLayoutContent } from './BaseLayoutContent';
import { ContainerProps } from '@mui/material';

interface BreadcrumbItem {
  path: string;
  title: string;
  active?: boolean;
}

interface BaseLayoutWithChildrenProps {
  children: React.ReactNode;
  heading?: string;
  size?: ContainerProps['maxWidth'];
  customBreadcrumbs?: Array<BreadcrumbItem>;
}

export const BaseLayoutWithChildren = ({ children, heading, size, customBreadcrumbs }: BaseLayoutWithChildrenProps) => {
  return (
    <BaseLayoutContent heading={heading} size={size} customBreadcrumbs={customBreadcrumbs}>
      {children}
    </BaseLayoutContent>
  );
};
