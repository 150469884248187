import React, { useEffect } from 'react';
import { Box } from '@zitcha/component-library';
import { useForm } from 'react-hook-form';
import { RenderModalInputs } from './RenderModalInputs';
import { ModalButton, ModalFooter } from '../components/ModalFooter';
import { CreateRoleData } from './hooks/useAddRoleWithHandler';
import { ErrorType } from 'v2/lib/axios/axiosMainInstance';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';

export interface FormValues {
  name: string;
  description: string;
  permissionSets: Array<OptionsType>;
}

type CreateRoleErrorsType = Record<string, Array<string>>;

interface CreateRoleProps {
  secondaryButton: ModalButton;
  isPending?: boolean;
  handleCreateRole: (changedFields: CreateRoleData) => Promise<ErrorType<Error>>;
}

export const CreateRole: React.FC<CreateRoleProps> = ({ secondaryButton, isPending = false, handleCreateRole }) => {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      permissionSets: [],
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    reset();
  }, []);

  const createRoleHandler = async (data: FormValues) => {
    const changedFields = {
      data: {
        name: data.name,
        description: data.description,
        permissionSets: data.permissionSets.map((permissionSet) => permissionSet.id) as unknown as Array<number>,
      },
    };

    const error = await handleCreateRole(changedFields);

    if (error) {
      const errors = error?.response?.data?.errors as CreateRoleErrorsType;

      Object.keys(errors).forEach((field: keyof FormValues) => {
        setError(field, { message: errors[field].join(' ') });
      });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(createRoleHandler)}>
        <RenderModalInputs control={control} errors={errors} setValue={setValue} />
        <ModalFooter primaryButton={{ label: 'Create role', isPending }} secondaryButton={secondaryButton} />
      </form>
    </Box>
  );
};
