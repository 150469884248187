import React, { ReactNode, useRef } from 'react';
import { useRouteError } from 'react-router-dom';

/**
 * This ErrorBoundary should only catch route related errors, all other errors should keep bubbling up and
 * be handled by another error boundary.
 */
export const RouteErrorBoundary = () => {
  const error = useRouteError();
  if (typeof error === 'object' && error !== null && 'status' in error && error.status === 404) {
    return <NotFound />;
  } else {
    throw error;
  }
};

const NotFound = () => (
  <div className='twd-w-full twd-h-screen twd-flex twd-items-center twd-justify-center'>
    <div className='twd-border-gray-200 twd-flex twd-flex-col twd-items-center twd-justify-center'>
      <p className='twd-text-9xl twd-font-bold twd-tracking-wider twd-text-gray-300'>404</p>
      <p className=' twd-5xl twd-font-bold twd-tracking-wider twd-text-gray-500 mt-4'>Page Not Found</p>
      <p className='twd-text-gray-500 twd-mt-4 twd-pb-4 twd-text-center'>
        Sorry, the page you are looking for could not be found.
      </p>
    </div>
  </div>
);

/**
 * Throws a 404 error when the `when` condition is true. This error is caught by the RouteErrorBoundary.
 * This is useful for conditionally restricting access to a page, such as based on feature flags or user roles.
 */
export const Throw404 = ({ when, otherwise }: { when: boolean; otherwise: ReactNode }): ReactNode => {
  const thrown = useRef(false);
  if (when && !thrown.current) {
    thrown.current = true;
    throw new Response('Not Found', { status: 404 });
  }
  return otherwise;
};
