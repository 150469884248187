import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { auth0Audience, auth0ClientId, auth0Domain } from 'envVars';
import axios, { axiosSetUp } from '../../axios';
import { AXIOS_INSTANCE, axiosSetUp as axiosV2Setup } from 'v2/lib/axios/axiosMainInstance';
import store from 'app/store';

const InnerLayout = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  axiosSetUp(axios, store, getAccessTokenSilently, isAuthenticated);
  axiosV2Setup(AXIOS_INSTANCE, store, getAccessTokenSilently, isAuthenticated);

  return <Outlet />;
};

const Auth0ProviderLayout = () => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || '/', { replace: true });
  };

  return (
    <Auth0Provider
      domain={auth0Domain()}
      clientId={auth0ClientId()}
      authorizationParams={{
        redirect_uri: window.location.origin + '/callback',
        audience: auth0Audience(),
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <InnerLayout />
    </Auth0Provider>
  );
};

export default Auth0ProviderLayout;
