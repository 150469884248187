import React from 'react';
import { Box, SaveIcon } from '@zitcha/component-library';
import { useForm } from 'react-hook-form';
import { FormValues } from './CreateRole';
import { LoadingModalData } from '../components/LoadingModalData';
import { RenderModalInputs } from './RenderModalInputs';
import { useGetRoleFormData } from './hooks/useGetRoleFormData';
import { ModalButton, ModalFooter } from '../components/ModalFooter';
import { UpdateRoleData } from './hooks/useUpdateRoleWithHandler';

interface EditRoleProps {
  roleId: number | null;
  secondaryButton: ModalButton;
  handleUpdateRole: (changedFields: UpdateRoleData) => Promise<void>;
  isPending?: boolean;
}

export const EditRole: React.FC<EditRoleProps> = ({ roleId, secondaryButton, handleUpdateRole, isPending = false }) => {
  const { name, description, permissionSets, isLoading, isError } = useGetRoleFormData({ roleId });

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      permissionSets: [],
    },
    mode: 'onBlur',
    values: {
      name: name || '',
      description: description || '',
      permissionSets: permissionSets,
    },
  });

  const updateRoleHandler = async (data: FormValues) => {
    const changedFields = {
      roleId: Number(roleId),
      data: {
        name: data.name,
        description: data.description,
        permissionSets: data.permissionSets.map((permissionSet) => permissionSet.id) as unknown as Array<number>,
      },
    };

    await handleUpdateRole(changedFields);
  };

  if (isLoading || isError) return <LoadingModalData isLoading={isLoading} isError={isError} subject={'role data'} />;

  return (
    <Box>
      <form onSubmit={handleSubmit(updateRoleHandler)}>
        <RenderModalInputs control={control} errors={errors} setValue={setValue} mode={'editing'} />
        <ModalFooter
          primaryButton={{
            label: 'Save changes',
            icon: SaveIcon,
            type: 'submit',
            isPending,
          }}
          secondaryButton={secondaryButton}
        />
      </form>
    </Box>
  );
};
