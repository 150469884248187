import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NETWORK_BRANDING } from './queryKeys';
import { useSelector } from 'react-redux';
import { selectSession, selectUser } from 'features/session/sessionSlice';
import {
  getNetworkBranding,
  updateNetworkBranding,
  NetworkBrandingResource,
  UpdateNetworkBrandingRequest,
} from 'v2/lib/api/system-management';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';

const emptyNetworkBranding = { data: { settings: { theme: {}, network_logo: {} } } };

const getTheme = async (retailerId: string): Promise<NetworkBrandingResource> => {
  if (retailerId) {
    try {
      return getNetworkBranding(retailerId);
    } catch {
      return emptyNetworkBranding;
    }
  } else {
    return emptyNetworkBranding;
  }
};

export default function useNetworkBranding({ shouldFetch } = { shouldFetch: true }) {
  const session = useSelector(selectSession);
  const organisation = session?.user?.active_organisation;
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;
  const { retailerId } = useNetworkBrandingContext();
  const [orgId, setOrgId] = useState<string>('');

  useEffect(() => {
    if (isRetailer) {
      setOrgId(organisation?.id);
    } else if (retailerId) {
      setOrgId(retailerId);
    } else {
      setOrgId('');
    }
  }, [organisation?.id, isRetailer, retailerId]);

  const { data, isError, isInitialLoading, isLoading } = useQuery({
    queryKey: [NETWORK_BRANDING, orgId],
    queryFn: () => getTheme(orgId),
    retry: 1,
    enabled: shouldFetch,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    retryOnMount: false,
  });

  return {
    data,
    isError,
    isLoading,
    isInitialLoading,
  };
}

export const useNetworkBrandingMutation = () => {
  const session = useSelector(selectSession);
  const organisation = session?.user.active_organisation;
  const queryClient = useQueryClient();
  const { startProcess, finishProcess } = useProcessingContext();
  const mutation = useMutation({
    mutationFn: (data: UpdateNetworkBrandingRequest) => updateNetworkBranding(data),
    onMutate: async (settings) => {
      const processId = startProcess('Saving settings');
      await queryClient.cancelQueries({ queryKey: [NETWORK_BRANDING, organisation?.id] });
      const snapshot = queryClient.getQueryData([NETWORK_BRANDING, organisation?.id]);
      queryClient.setQueryData([NETWORK_BRANDING, organisation?.id], () => ({
        settings,
      }));
      return { snapshot, processId };
    },
    onError: (error, variables, context) => {
      if (context?.processId) {
        finishProcess(context.processId, { success: false, message: 'Error updating network branding' });
      }
      queryClient.invalidateQueries({ queryKey: [NETWORK_BRANDING, organisation?.id] });
    },
    onSuccess: (data, variables, context) => {
      if (context?.processId) {
        finishProcess(context.processId, { success: true, message: 'Network branding updated' });
      }
      queryClient.invalidateQueries({ queryKey: [NETWORK_BRANDING, organisation?.id] });
    },
  });

  return mutation;
};

type NetworkBrandingContextProps = {
  setRetailerId: (retailerId: string | undefined) => void;
  retailerId: string | undefined;
};

const NetworkBrandingContext = createContext<NetworkBrandingContextProps | undefined>(undefined);

export const NetworkBrandingProvider = ({ children }: { children: React.ReactNode }) => {
  const [retailerId, setRetailerId] = useState<string | undefined>();

  return (
    <NetworkBrandingContext.Provider
      value={{
        setRetailerId,
        retailerId,
      }}
    >
      {children}
    </NetworkBrandingContext.Provider>
  );
};

export const useNetworkBrandingContext = () => {
  const context = useContext(NetworkBrandingContext);

  useEffect(() => {
    return () => {
      context && context.setRetailerId(undefined);
    };
  }, []);

  if (!context) {
    throw new Error('useNetworkBrandingContext must be used within a NetworkBrandingProvider');
  }

  return context;
};
