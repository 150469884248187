import React from 'react';
import { Box, Button, GroupAddIcon } from '@zitcha/component-library';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button/Button';

// TODO: import from the library?
interface ButtonProps extends Omit<MuiButtonProps, 'variant' | 'size' | 'color' | 'startIcon' | 'endIcon'> {
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
}

export interface ModalButton {
  label: string;
  onClick?: () => void;
  type?: 'button' | 'submit';
  variant?: 'normal' | 'warning' | 'close';
  icon?: React.FC;
  isPending?: boolean;
}

interface ModalFooterProps {
  primaryButton: ModalButton;
  secondaryButton: ModalButton;
}

export const ModalFooter = ({
  primaryButton: {
    label: primaryButtonLabel = 'Save changes',
    type: primaryButtonType = 'submit',
    icon: PrimaryButtonIcon = () => <GroupAddIcon />,
    onClick: primaryButtonAction,
    isPending: isPrimaryActionPending,
  },
  secondaryButton: {
    label: secondaryButtonLabel = 'Cancel',
    variant: secondaryButtonVariant = 'normal',
    onClick: secondaryButtonAction,
    isPending: isSecondaryActionPending,
  },
}: ModalFooterProps) => {
  let secondaryButtonExtraProps;

  switch (secondaryButtonVariant) {
    case 'warning':
      secondaryButtonExtraProps = { variant: 'contained', color: 'error' };
      break;
    case 'close':
      secondaryButtonExtraProps = {
        sx: {
          color: 'black',
        },
        variant: 'text',
        color: 'error',
      };
      break;
    default:
      secondaryButtonExtraProps = { variant: 'text', color: 'error' };
      break;
  }

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='2rem'>
      <Button
        type='button'
        onClick={secondaryButtonAction}
        {...(secondaryButtonExtraProps as Partial<ButtonProps>)}
        disabled={isSecondaryActionPending}
      >
        {secondaryButtonLabel}
      </Button>
      <Button
        startIcon={<PrimaryButtonIcon />}
        disabled={isPrimaryActionPending}
        variant='contained'
        type={primaryButtonType}
        onClick={() => {
          primaryButtonAction?.();
        }}
      >
        {primaryButtonLabel}
      </Button>
    </Box>
  );
};
