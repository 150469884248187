import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Tabs, Tab, Alert, TuneIcon, ListAltIcon } from '@zitcha/component-library';
import { selectSession, selectAdTypes, refreshSession } from '../../features/session/sessionSlice';
import { cachedFetchBrands, selectStatus } from '../../features/brands/brandsSlice';
import { GlobalSupplierSettings } from './GlobalSupplierSettings';
import { SupplierSpecificSettings } from './SuppliersSpecificSettings';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import { updateOrganisationSettings } from 'lib/api';
import { trackEnableChannel } from 'helpers/segment';
import { reloadAllOrders } from 'features/orders/ordersSlice';
import { clearSession } from '../../features/session/sessionSlice';
import { findDifferentFalseyKeys } from '../../lib/util/objects';
import { atof } from '../../lib/financial';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const ENABLE_META_SETTINGS_FEATURE = true;

const METRICS_FORM_KEYS = [
  'clicks',
  'inline_link_clicks',
  'view_rate',
  'views',
  'impressions',
  'reach',
  'online_sales',
  'offline_sales',
  'cpc',
  'cost_per_inline_link_click',
  'cpm',
  'cpv',
  'ctr',
  'spend',
  'lifetime_budget',
  'daily_budget',
  'total_roas',
  'total_conversion_value',
  'direct_conversions_value',
  'associated_conversions_value',
  'webads_total_conversions_value',
  'webads_total_conversions_count',
];

const PERMISSIONS_FORM_KEYS = ['bid_amount', 'budget', 'skew_metrics', 'skew_metrics_retailer', 'require_order_wallet'];

const SuppliersGeneralSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const { organisations: suppliers } = useScopedOrganisations({});

  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const permissions = organisation?.permissions;
  const adSettings = organisation?.settings?.ad_settings;
  const [msg, setMsg] = useState(null);
  const adTypes = useSelector(selectAdTypes);
  const isLoading = useSelector((state) => selectStatus(state) === 'loading');
  const hasFacebookMPAEnabled = session?.feature_flags?.ad_types?.facebook_managed_partner_ad || false;

  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ...METRICS_FORM_KEYS.reduce((acc, key) => {
        acc[key] = organisation?.permissions?.metrics?.insights?.[key] || false;
        return acc;
      }, {}),
      ...PERMISSIONS_FORM_KEYS.reduce((acc, key) => {
        acc[key] = organisation?.permissions?.metrics?.[key] || false;
        return acc;
      }, {}),
      supplier_inventory_enabled: organisation?.settings?.supplier_inventory_enabled || false,
    },
  });

  const {
    setError,
    setValue,
    handleSubmit,
    control,
    register,
    formState: { isSubmitting },
  } = methods;

  const { startProcess, finishProcess } = useProcessingContext();

  const onSubmit = async (data) => {
    setMsg(null);
    const processId = startProcess('Saving settings');

    // Create a deep copy of the current organisation settings
    const updatedSettings = structuredClone(organisation.settings ?? {});

    // Update ad_settings
    updatedSettings.ad_settings = {
      ...updatedSettings.ad_settings,
      margin: {},
      min_budget: {},
      enabled: {},
      facebook_custom_audiences: data.facebook_custom_audiences || [],
      facebook_lookalike_audiences: data.facebook_lookalike_audiences || [],
      facebook_saved_audiences: data.facebook_saved_audiences || [],
      google_remarketing_audiences: data.google_remarketing_audiences || [],
      page_handlers: data.page_handlers || [],
    };

    adTypes.forEach((adType) => {
      if (data?.[adType?.control_id]) {
        if (adType.platform !== 'web') {
          updatedSettings.ad_settings.margin[adType.control_id] = parseFloat(data[adType.control_id].margin);
          if (data[adType.control_id].min_budget) {
            updatedSettings.ad_settings.min_budget[adType.control_id] = parseFloat(data[adType.control_id].min_budget);
          }
        }
        updatedSettings.ad_settings.enabled[adType.control_id] = data[adType.control_id].enabled;
      }
    });

    // Update other settings
    updatedSettings.platform_webad_tracking = data.platform_webad_tracking;
    updatedSettings.supplier_inventory_enabled = data.supplier_inventory_enabled;

    const updatedPermissions = {
      metrics: {
        insights: {},
      },
    };
    METRICS_FORM_KEYS.forEach((key) => {
      updatedPermissions.metrics.insights[key] = data?.[key];
    });
    PERMISSIONS_FORM_KEYS.forEach((key) => {
      updatedPermissions.metrics[key] = data?.[key];
    });

    try {
      const res = await updateOrganisationSettings(organisation.id, {
        settings: updatedSettings,
        permissions: updatedPermissions,
      });
      findDifferentFalseyKeys(
        organisation?.settings?.ad_settings?.enabled || {},
        res?.data?.data?.settings?.ad_settings?.enabled || {}
      ).forEach((adformat) => {
        const margin = atof(organisation?.settings?.ad_settings?.margin[adformat]);
        const minBudget = atof(organisation?.settings?.ad_settings?.min_budget[adformat]);
        const platform = adTypes.find((adType) => adType.control_id === adformat)?.platform;
        trackEnableChannel(adformat, platform, undefined, margin, minBudget);
      });

      setOrganisation(res.data.data);
      dispatch(refreshSession());
      dispatch(reloadAllOrders());
      finishProcess(processId, { success: true, message: 'Settings saved' });
    } catch (e) {
      if (e.response?.status === 403) {
        dispatch(clearSession());
        navigate('/login');
      } else {
        const errors = e?.response?.data?.errors;
        const keys = Object.keys(errors || {});
        if (errors || keys?.length) {
          setMsg({
            type: 'danger',
            body: 'Could not save the settings, please try again.',
          });
          finishProcess(processId, { success: false, message: 'Could not save the settings, please try again' });
          keys.forEach((errorKey) => {
            const key = errorKey.replace('settings.ad_settings.', '');
            const splitKey = key.split('.');
            if (splitKey?.length === 2) {
              setError(`${splitKey[1]}.${splitKey[0]}`, {
                type: 'manual',
                message: errors[`settings.ad_settings.${key}`][0],
              });
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    register('facebook_custom_audiences');
    register('facebook_lookalike_audiences');
    register('facebook_saved_audiences');
    register('google_remarketing_audiences');
    if (ENABLE_META_SETTINGS_FEATURE) {
      register('page_handlers');
    }

    dispatch(cachedFetchBrands());
  }, [dispatch, register]);

  useEffect(() => {
    const currentTab = searchParams.get('tab');
    if (!currentTab || !['general-supplier-settings', 'supplier-specific-settings'].includes(currentTab)) {
      setSearchParams((prev) => {
        prev.set('tab', 'general-supplier-settings');
        return prev;
      });
    }
  }, [searchParams, setSearchParams]);

  const handleTabChange = (_, newValue) => {
    setSearchParams((prev) => {
      prev.set('tab', newValue);
      return prev;
    });
  };

  const currentTab = searchParams.get('tab') || 'general-supplier-settings';

  return (
    <BaseLayoutWithChildren heading='Supplier settings' size='lg'>
      <FormProvider {...methods}>
        {msg && (
          <Alert severity={msg.type === 'danger' ? 'error' : msg.type} sx={{ mt: 2 }}>
            {msg.body}
          </Alert>
        )}

        <PageSection id='supplier-settings-tabs'>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label='Supplier Settings Tabs'>
            <Tab
              label='Global'
              value='general-supplier-settings'
              icon={<TuneIcon />}
              iconPosition='start'
              sx={{
                minHeight: '35px',
              }}
            />
            <Tab
              label='Suppliers'
              value='supplier-specific-settings'
              icon={<ListAltIcon />}
              iconPosition='start'
              sx={{
                minHeight: '35px',
              }}
            />
          </Tabs>
        </PageSection>

        <PageSection id='supplier-settings-content'>
          {currentTab === 'general-supplier-settings' ? (
            <GlobalSupplierSettings
              control={control}
              setValue={setValue}
              permissions={permissions}
              adSettings={adSettings}
              adTypes={adTypes}
              organisation={organisation}
              hasFacebookMPAEnabled={hasFacebookMPAEnabled}
              ENABLE_META_SETTINGS_FEATURE={ENABLE_META_SETTINGS_FEATURE}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit(onSubmit)}
            />
          ) : (
            <SupplierSpecificSettings suppliers={suppliers} isLoading={isLoading} navigate={navigate} />
          )}
        </PageSection>
      </FormProvider>
    </BaseLayoutWithChildren>
  );
};

export default SuppliersGeneralSettings;
