import { switchOrganisation } from 'lib/api';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTheme, Box, Typography } from '@zitcha/component-library';

interface OrganisationViewProps {
  id: string;
  logoUrl: string;
  name: string;
  isActive: boolean;
}

function OrganisationView({ id, logoUrl, name, isActive }: OrganisationViewProps) {
  const theme = useTheme();
  const handleOrganisationSwitch = useCallback(
    async (id: string) => {
      if (isActive || !id) return;
      try {
        await switchOrganisation(id);
        window.location.assign(window.location.origin);
      } catch (e) {
        const message = e?.response?.data?.message;
        if (message && !!message.length) {
          toast.error(message);
        }
      }
    },
    [id]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem ',
        borderRadius: `${theme.shape.borderRadius}px`,
        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.03)' : '',
        '&:hover': {
          backgroundColor: isActive ? '' : 'rgba(0, 0, 0, 0.01)',
        },
        cursor: 'pointer',
        transition: 'background-color 0.2s',
      }}
      onClick={() => handleOrganisationSwitch(id)}
    >
      {logoUrl ? (
        <img src={logoUrl} alt={name} className='twd-object-contain twd-max-h-8 twd-w-auto twd-max-w-16' />
      ) : (
        <div className='twd-flex twd-items-center twd-justify-center twd-bg-gray-200 twd-max-h-8 twd-w-auto twd-max-w-16 h-8'>
          <span className='twd-text-xs twd-text-gray-600 twd-p-1'>No Logo</span>
        </div>
      )}
      <Typography variant='body1'>{name}</Typography>
    </Box>
  );
}
export { OrganisationView };
