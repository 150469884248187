import React, { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectSession } from '../features/session/sessionSlice';
import $styles from './organisation_channels.module.scss';
import { AdType } from '../lib/enums/ad_types';
import { Button } from '@zitcha/component-library';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const ChannelItem = ({ logo, title, settingsPath, description }) => {
  const navigate = useNavigate();

  return (
    <div className='d-flex'>
      {/* <Figure>
        <Figure.Image className="img-responsive" src={logo} rounded />
      </Figure> */}
      <div className={$styles.channelItemDiv}>
        <h4 className={$styles.channelItemTitle}>{title}</h4>
        <p className={$styles.channelItemDesc}>{description}</p>
        <Button onClick={() => navigate(settingsPath)} size='large'>
          Settings
        </Button>
      </div>
    </div>
  );
};

const OrganisationChannels = () => {
  const session = useSelector(selectSession);
  const hasBroadsignEnabled = session?.feature_flags?.ad_types?.broadsign_in_store || false;

  const adTypes = useSelector(selectSession).ad_types;

  const getAdTypeLogo = useCallback(
    (type) => {
      const adType = adTypes.find((e) => e.control_id === type);
      return adType ? adType.placeholder_images?.large : null;
    },
    [adTypes]
  );

  return (
    <BaseLayoutWithChildren heading='Owned organisation channels' size='lg'>
      <PageSection>
        <Form>
          <Row>
            <Col sm='6' xs='12'>
              <ChannelItem
                logo={getAdTypeLogo('web_ads')}
                title='Web Ads'
                description='Manage your onsite spaces and templates.'
                settingsPath='/organisation/websettings'
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <div className='d-flex'>
                <h3 className={$styles.topHeading}>Extension Channels</h3>
              </div>
            </Col>
          </Row>
          <Row>
            {hasBroadsignEnabled && (
              <Col sm='6' xs='12'>
                <ChannelItem
                  logo={getAdTypeLogo(AdType.BROADSIGN)}
                  title='In-Store Screens'
                  description='Manage your In-Store Screens.'
                  settingsPath='/organisation/instorescreens-settings'
                />
              </Col>
            )}
            <Col sm='6' xs='12'>
              <ChannelItem
                logo={getAdTypeLogo('bespoke_product')}
                title='Bespoke Products'
                description='Manage your physical and digital Bespoke Products.'
                settingsPath='/organisation/bespokesettings'
              />
            </Col>
          </Row>
        </Form>
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default OrganisationChannels;
