import store from './app/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { init as LogRocketInit } from './helpers/logrocket';
import { init as SegmentInit } from './helpers/segment';
import AppQueryClientProvider from 'AppQueryClientProvider';
import { worker } from './msw.client';

LogRocketInit();

SegmentInit();

const boot = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppQueryClientProvider>
          <App />
        </AppQueryClientProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

if (process.env.NODE_ENV === 'development' && localStorage.MSW_DISABLED !== 'true') {
  worker
    .start({ onUnhandledRequest: 'bypass', waitUntilReady: true, quiet: true })
    .then(() => {
      boot();
    })
    // Every other reload MSW fails to boot, @todo: investigate and fix
    .catch((e) => {
      // eslint-disable-next-line no-console -- Developer should know that their mocked API might not be in effect
      console.info('%cFailed to start MSW, booting without MSW', 'color: #5b6ac4; font-weight: bold;');
      // eslint-disable-next-line no-console
      console.error(e);
      boot();
    });
} else {
  boot();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
