import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { selectDialog } from 'app/appSlice';
import { ToastContainer } from 'react-toastify';
import AppDialog from './app/Dialog';
import { randomRange } from 'lib/random';
import { isLoggedIn, refreshSession } from 'features/session/sessionSlice';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import { trackPageView } from 'helpers/segment';
import { HelmetProvider } from 'react-helmet-async';
import StyleSheetManagerProvider from './providers/StyleSheetManagerProvider';
import { getRoutes } from 'routes';
import '@zitcha/component-library/dist/style.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';
import { ProcessingProvider } from 'v2/features/GlobalNotifications/ProcessingContext';
import DynamicFavicon from 'DynamicFavicon';
import { NetworkBrandingProvider } from 'app/OrganisationSettings/useNetworkBranding';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import { useRetailerSettings } from 'v2/lib/hooks/useRetailerSettings';

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);
  return <></>;
};

const App = () => {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const dialog = useSelector(selectDialog);
  const { data: featureFlags } = useGetFeatureFlags();
  const { organisations: scopedRetailers } = useScopedOrganisations({});
  const { data: scopeRetailerWithSettings } = useRetailerSettings({
    ids: scopedRetailers.map((retailer) => retailer.id),
  });

  const retailerWithInventoryEnabled = scopeRetailerWithSettings?.filter(
    (retailer) => retailer.settings.supplier_inventory_enabled
  );

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (isLoggedIn(session)) {
          dispatch(refreshSession());
        }
      },
      randomRange(3, 5) * 60 * 1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [session, dispatch]);

  const hideInventoryRoute =
    retailerWithInventoryEnabled.length === 0 && !session?.data?.user?.active_organisation?.is_retailer;
  const routes = getRoutes(featureFlags, hideInventoryRoute);
  const router = createBrowserRouter(routes);

  return (
    <StyleSheetManagerProvider>
      <HelmetProvider>
        <DynamicFavicon />
        <ProcessingProvider>
          <NetworkBrandingProvider>
            <RouterProvider router={router}>
              <PageViewTracker />
              {!!dialog && <AppDialog dialog={dialog} />}
              <ToastContainer autoClose={5000} />
            </RouterProvider>
          </NetworkBrandingProvider>
        </ProcessingProvider>
        <ReactQueryDevtools initialIsOpen={true} buttonPosition='bottom-right' />
      </HelmetProvider>
    </StyleSheetManagerProvider>
  );
};

export default App;
