import React from 'react';
import { Box, Button, DeleteIcon, IconButton, Typography } from '@zitcha/component-library';
import { LinearProgress } from '@mui/material';
import { NetworkBrandingSettings } from 'v2/lib/api/system-management';

interface FileUploadProps {
  label: string;
  subLabel?: string;
  value: NetworkBrandingSettings['network_logo'];
  backgroundColor: string;
  onChange: (file: File | null) => void;
  onReset: () => void;
  hasLogo: boolean;
  uploadProgress: number;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  label,
  subLabel,
  value,
  backgroundColor,
  onChange,
  onReset,
  hasLogo,
  uploadProgress,
}) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant='subtitle1'>{label}</Typography>
      <Typography variant='input.helper' color='text.secondary'>
        {subLabel}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} mt={1}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'auto',
            minHeight: 37,
            padding: 1,
            backgroundColor: hasLogo ? 'background.paper' : 'inherit',
            border: hasLogo ? 'none' : '2px dashed',
            borderColor: 'divider',
          }}
        >
          {hasLogo ? (
            <Box
              sx={{
                backgroundColor,
              }}
              p={1}
              minWidth={200}
            >
              <img
                src={`${value?.url}?r=${Math.random()}`}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: 40,
                  maxHeight: '100%',
                }}
                alt='network logo'
              />
            </Box>
          ) : (
            <Typography variant='body1' color='divider'>
              Upload your network logo
            </Typography>
          )}
        </Box>
        <Button variant='contained' component='label' sx={{ maxWidth: 300 }}>
          {hasLogo ? 'Change network logo' : 'Choose file'}
          <input type='file' hidden onChange={(e) => onChange(e.target.files?.[0] || null)} />
        </Button>
        {hasLogo && (
          <IconButton variant='outlined' color='error' onClick={onReset}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      {uploadProgress > 0 && (
        <LinearProgress variant='determinate' value={uploadProgress} sx={{ mt: 2, maxWidth: 300 }} />
      )}
    </Box>
  );
};
