import React from 'react';
import { Box, Typography, Tooltip } from '@zitcha/component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { ColorPicker } from '../components/ColorPicker';

interface ColorPickerFieldProps {
  label: string;
  color: string;
  onChange: (color: string) => void;
  disabled?: boolean;
  tooltip?: string;
}

export const ColorPickerField: React.FC<ColorPickerFieldProps> = ({
  label,
  color,
  onChange,
  disabled = false,
  tooltip,
}) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
        <Typography variant='subtitle1'>{label}</Typography>
        {tooltip && (
          <Tooltip title={tooltip} arrow>
            <FontAwesomeIcon icon={faCircleInfo} size='sm' />
          </Tooltip>
        )}
      </Box>
      <ColorPicker color={color} setColor={onChange} disabled={disabled} />
    </Box>
  );
};
