import React, { useState } from 'react';
import {
  AddIcon,
  Alert,
  Box,
  Chip,
  DataGrid,
  EditIcon,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  VisibilityIcon,
  GridRenderCellParams,
} from '@zitcha/component-library';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { TeamManagementTableFilters } from './TeamManagementTableFilters';
import { useFetchTeams } from 'v2/lib/hooks/useFetchTeams';
import { GetTeamsParams, PerPageEnum } from 'v2/lib/api/access-management';
import { ModalState, TeamManagementModal } from './TeamManagementModal';
import { GenericTabHeader } from '../components/GenericTabHeader';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { SettingsTableContainer } from '../components/SettingsTableContainer';

export const TeamManagement = () => {
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    mode: 'view',
    teamId: null,
    title: '',
  });

  const handleClose = () => {
    setModalState({ open: false, mode: 'view', teamId: null, title: '' });
  };

  const { teams, teamsPagination, changeTeamsPagination, isLoadingTeams, teamsError, setTeamsSearch } = useFetchTeams({
    q: undefined,
    organisationIds: undefined,
    productSetIds: undefined,
    page: 1,
    perPage: undefined,
  });
  const { data: featureFlags } = useGetFeatureFlags();
  const organisation = useUserOrganisation();
  const isPlansEnabled = featureFlags?.feature_planning;

  const rows = teams.map((team) => ({
    team: team.name,
    members: team.scope?.users ? <Chip label={team.scope?.users} /> : <></>,
    brands: team.scope?.organisations ? <Chip label={team.scope.organisations} /> : <></>,
    product_sets: team.scope?.productSets ? <Chip label={team.scope.productSets} /> : <></>,
    orders: team.scope?.orders ? <Chip label={team.scope.orders} /> : <></>,
    ...(isPlansEnabled ? { plans: team.scope?.plans ? <Chip label={team.scope.plans} /> : <></> } : {}),
    wallets: team.scope?.wallets ? <Chip label={team.scope.wallets} /> : <></>,
    id: team.id,
  }));

  const columns: Array<GridColDef> = [
    {
      field: 'team',
      headerName: 'Team',
      minWidth: 150,
    },
    {
      field: 'members',
      headerName: 'Members',
      renderCell: (params) => params.value,
      minWidth: 80,
    },
    {
      field: 'brands',
      headerName: organisation?.is_retailer ? 'Brand(s)' : 'Retailer(s)',
      renderCell: (params) => params.value,
      minWidth: 80,
    },
    {
      field: 'product_sets',
      headerName: 'Product Sets',
      renderCell: (params) => params.value,
      minWidth: 80,
    },
    {
      field: 'orders',
      headerName: 'Orders',
      renderCell: (params) => params.value,
      minWidth: 80,
    },
    ...(isPlansEnabled
      ? [
          {
            field: 'plans',
            headerName: 'Plans',
            renderCell: (params: GridRenderCellParams) => params.value,
            minWidth: 80,
          },
        ]
      : []),
    {
      field: 'wallets',
      headerName: 'Wallets',
      renderCell: (params) => params.value,
      minWidth: 80,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      width: 96,
      cellClassName: 'actions',
      align: 'right',
      type: 'actions',
      flex: 1,
      getActions: ({ id }: GridRowParams) => {
        return [
          <GridActionsCellItem
            key='edit'
            icon={<VisibilityIcon />}
            label='View'
            aria-label='view team'
            className='textPrimary'
            onClick={() => {
              setModalState({ open: true, mode: 'view', teamId: Number(id), title: 'View Team' });
            }}
            color='default'
          />,
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            aria-label='edit team'
            className='textPrimary'
            onClick={() => {
              setModalState({ open: true, mode: 'edit', teamId: Number(id), title: 'Edit Team' });
            }}
            color='default'
          />,
        ];
      },
    },
  ];

  const handleFilterChange = (newFilter: Partial<GetTeamsParams>) => {
    setTeamsSearch((prevFilter) => ({ ...prevFilter, ...newFilter }));
  };

  const dataGridPaginationModel = {
    pageSize: teamsPagination.perPage ?? PerPageEnum.NUMBER_5,
    page: (teamsPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeTeamsPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  return (
    <Box pt={2.5}>
      <GenericTabHeader
        title='Team Management'
        subtitle='Manage teams relative to their scope within your organisation.'
        buttonText='New Team'
        buttonIcon={<AddIcon />}
        onButtonClick={() => {
          setModalState({ open: true, mode: 'edit', teamId: null, title: 'Create New Team' });
        }}
      />

      <SettingsTableContainer>
        <TeamManagementTableFilters onFilterChange={handleFilterChange} />
        {teamsError && <Alert severity='error'>An error occurred while fetching Teams</Alert>}
        <DataGrid
          isRowSelectable={() => false}
          disableColumnFilter
          disableColumnSorting
          autoHeight
          loading={isLoadingTeams}
          paginationMode={'server'}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowCount={teamsPagination?.total || 0}
          paginationModel={dataGridPaginationModel}
          onPaginationModelChange={handlePaginationUpdate}
          columns={columns}
          rows={rows}
        />
        {modalState.open && (
          <TeamManagementModal modalState={modalState} handleClose={handleClose} handleModalState={setModalState} />
        )}
      </SettingsTableContainer>
    </Box>
  );
};
