import React from 'react';
import Invite from '../features/organisations/Invite';
import { INVITE_SUPPLIERS } from 'lib/permissions';
import { Navigate } from 'react-router-dom';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const InviteSupplier = () => {
  const { hasPermission } = usePermissions();
  const canInviteSuppliers = hasPermission(INVITE_SUPPLIERS);

  if (!canInviteSuppliers) {
    return <Navigate to='/' replace />;
  }

  return (
    <BaseLayoutWithChildren heading='Invite supplier organisation' size='lg'>
      <PageSection id='invite-supplier-section'>
        <Invite />
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default InviteSupplier;
