import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Inventory from '@mui/icons-material/Inventory';
import { MenuItem } from './MenuItem';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import { useRetailerSettings } from 'v2/lib/hooks/useRetailerSettings';
import { RetailerSelectionModal } from '../RetailerSelectionModal/RetailerSelectionModal';
export const SupplierInventoryNavigation = ({ onlyIcon }: { onlyIcon: boolean }) => {
  const [organisationModalOpen, setSelectOrgansationModalOpen] = useState<boolean>(false);
  const { organisations: scopedRetailers } = useScopedOrganisations({});
  const { data: scopeRetailerWithSettings } = useRetailerSettings({
    ids: scopedRetailers.map((retailer) => retailer.id) as Array<string>,
  });

  const navigate = useNavigate();
  const handleOrganisationSelection = useCallback(
    (id: string) => {
      //navigate to inventory page with the selected retailer as navigation state
      navigate('/inventory', { state: { retailerId: id } });
      setSelectOrgansationModalOpen(false);
    },
    [navigate]
  );
  const toggleOrganisationModal = () => {
    setSelectOrgansationModalOpen(!organisationModalOpen);
  };
  const retailersWithInventoryEnabled = scopeRetailerWithSettings?.filter(
    (retailer) => retailer.settings.supplier_inventory_enabled
  );

  if (!retailersWithInventoryEnabled || retailersWithInventoryEnabled.length === 0) return null;
  return (
    <>
      <MenuItem Icon={Inventory} onClick={toggleOrganisationModal} title='Inventory' onlyIcon={onlyIcon} />
      <RetailerSelectionModal
        heading={'Choose a retailer to view inventory'}
        isOpen={organisationModalOpen}
        onClose={toggleOrganisationModal}
        onClick={handleOrganisationSelection}
        retailers={retailersWithInventoryEnabled}
      />
    </>
  );
};
