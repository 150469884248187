import React, { useCallback, useEffect, useState } from 'react';
import WalletsView from '../features/wallets/WalletsView';
import QueryResult from '../features/wallets/QueryResult';
import { getRetailerWallets } from '../lib/api/wallets';
import { useRequest } from '../lib/hooks/useRequest';
import NoWallet from '../features/wallets/NoWallet';
import { useNavigate } from 'react-router-dom';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';

const RetailerWallets = () => {
  const [retailers, setRetailers] = useState([]);
  const navigate = useNavigate();

  const handleWalletSelection = useCallback(
    (wallet) => {
      navigate(`/wallet/${wallet.id}`);
    },
    [navigate]
  );

  const { response, loading, error } = useRequest(() => getRetailerWallets());

  useEffect(() => {
    if (response !== null) {
      setRetailers(response?.data);
    }
  }, [response]);

  return (
    <BaseLayoutWithChildren heading='Wallets' size='lg'>
      <PageSection id='supplier-list-section'>
        <QueryResult loading={loading} error={error}>
          {retailers.length === 0 ? (
            <NoWallet isSupplier={true} />
          ) : (
            retailers.map(({ retailer, wallets }) => (
              <div className='mb-5' key={retailer.id}>
                <p className='mb-1'>Retailer: {retailer.name}</p>
                <WalletsView
                  currency={retailer?.currency}
                  handleWalletSelection={handleWalletSelection}
                  isSupplier={true}
                  wallets={wallets}
                  loading={false}
                  error={null}
                />
              </div>
            ))
          )}
        </QueryResult>
      </PageSection>
    </BaseLayoutWithChildren>
  );
};

export default RetailerWallets;
