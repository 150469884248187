import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/session/sessionSlice';
import useNetworkBranding, { useNetworkBrandingMutation } from '../useNetworkBranding';
import { NetworkBrandingSettings, UpdateNetworkBrandingRequest } from 'v2/lib/api/system-management';
import { FormValues, ThemeObject } from './types';
import { isLightColor, isValidHex } from 'helpers/ColorHelpers';

const calculatePrimaryColors = (primaryMain: string) => {
  if (!isValidHex(primaryMain)) {
    return {
      main: primaryMain,
      dark: primaryMain,
      light: primaryMain,
      contrastText: '#FFFFFF',
    };
  }

  return {
    main: primaryMain,
    dark: primaryMain,
    light: `${primaryMain}B3`,
    contrastText: isLightColor(primaryMain) ? '#000000' : '#FFFFFF',
  };
};

export function useNetworkBrandingForm() {
  const { data: networkBranding, isLoading: isNetworkBrandingLoading } = useNetworkBranding({ shouldFetch: true });
  const [settings, setSettings] = useState<NetworkBrandingSettings | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const mutation = useNetworkBrandingMutation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const user = useSelector(selectUser);

  const defaultLogo = {
    creator_id: user.id,
    extension: 'png',
    name: 'zitcha_brandmark_white.png',
    path: '/Zitcha_Brandmark White.png',
    storage: 'locally',
    url: '/Zitcha_Brandmark White.png',
  };

  const defaultPrimaryColor = '#9841FF';
  const defaultValues: Partial<FormValues> = {
    networkLogo: defaultLogo,
    navbarBg: '#000',
    primaryColor: defaultPrimaryColor,
  };

  useEffect(() => {
    if (!isNetworkBrandingLoading && networkBranding?.data?.settings) {
      setSettings(networkBranding.data.settings);
      setTimeout(() => setIsLoading(false), 0); // Change to false after settings are updated
    }
  }, [isNetworkBrandingLoading, networkBranding]);

  const handleSave = async (data: FormValues) => {
    const themeDeepClone: ThemeObject = JSON.parse(JSON.stringify(settings?.theme ?? {}));

    if (data.navbarBg) {
      if (!themeDeepClone.components) themeDeepClone.components = {};
      if (!themeDeepClone.components.MuiAppBar) themeDeepClone.components.MuiAppBar = {};
      if (!themeDeepClone.components.MuiAppBar.styleOverrides) themeDeepClone.components.MuiAppBar.styleOverrides = {};
      if (!themeDeepClone.components.MuiAppBar.styleOverrides.root)
        themeDeepClone.components.MuiAppBar.styleOverrides.root = {};
      themeDeepClone.components.MuiAppBar.styleOverrides.root.backgroundColor = data.navbarBg;
    } else {
      if (themeDeepClone.components?.MuiAppBar?.styleOverrides?.root) {
        delete themeDeepClone.components.MuiAppBar.styleOverrides.root.backgroundColor;
      }
    }

    if (data.primaryColor) {
      if (!themeDeepClone.palette) themeDeepClone.palette = {};
      themeDeepClone.palette.primary = calculatePrimaryColors(data.primaryColor);
    } else {
      if (themeDeepClone.palette?.primary) {
        delete themeDeepClone.palette.primary;
      }
    }

    const newSettings: UpdateNetworkBrandingRequest = {
      network_logo: data.networkLogo,
      theme: themeDeepClone,
    };

    mutation.mutate(newSettings);
  };

  const handleLogoUpload = () => {
    setUploadProgress(0);
  };

  const handleLogoReset = (resetFormValues: (values: Partial<FormValues>) => void) => {
    // Update the settings state
    setSettings((prevSettings) => ({
      ...prevSettings,
      network_logo: defaultLogo,
      theme: {
        ...prevSettings?.theme,
        components: {
          ...prevSettings?.theme?.components,
          MuiAppBar: {
            ...prevSettings?.theme?.components?.MuiAppBar,
            styleOverrides: {
              ...prevSettings?.theme?.components?.MuiAppBar?.styleOverrides,
              root: {
                ...prevSettings?.theme?.components?.MuiAppBar?.styleOverrides?.root,
                backgroundColor: '#000',
              },
            },
          },
        },
        palette: {
          ...prevSettings?.theme?.palette,
          primary: calculatePrimaryColors(defaultPrimaryColor),
        },
      },
    }));

    // Reset form values
    resetFormValues(defaultValues);
  };

  return {
    settings,
    isLoading,
    mutation,
    handleSave,
    handleLogoUpload,
    handleLogoReset,
    uploadProgress,
    setUploadProgress,
    defaultValues,
  };
}
