import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import {
  clearBespokeProduct,
  fetchBespokeProduct,
  selectRequestType,
  selectStatus,
} from '../features/bespoke/bespokeFormSlice';
import CreateBespokeProduct from './CreateBespokeProduct';
import { BaseLayoutWithChildren } from 'v2/Layouts/BaseLayout/BaseLayoutWithChildren';
import { PageSection } from 'v2/Layouts/PageSection';
import { SimpleLoadingScreen } from 'v2/components/SimpleLoadingScreen';

const EditBespokeProduct = () => {
  const dispatch = useDispatch();
  const { bespoke_id } = useParams();
  const requestStatus = useSelector(selectStatus);
  const requestType = useSelector(selectRequestType);

  useEffect(() => {
    dispatch(clearBespokeProduct());
    dispatch(fetchBespokeProduct(bespoke_id));
  }, [dispatch, bespoke_id]);

  if (requestStatus === 'failed' && requestType === 'fetch') {
    return <Navigate to='/organisation/bespokesettings' replace />;
  }

  return (
    <BaseLayoutWithChildren heading='Edit bespoke product'>
      {requestStatus === 'loading' && requestType === 'fetch' ? (
        <SimpleLoadingScreen />
      ) : (
        <PageSection id='bespoke-editor-section'>
          <CreateBespokeProduct />
        </PageSection>
      )}
    </BaseLayoutWithChildren>
  );
};

export default EditBespokeProduct;
