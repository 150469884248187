import React, { useState } from 'react';
import {
  AddIcon,
  Alert,
  Box,
  Chip,
  DataGrid,
  EditIcon,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  VisibilityIcon,
} from '@zitcha/component-library';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { RoleManagementTableFilters } from './RoleManagementTableFilters';
import { PerPageEnum, RequestSearchRoles } from 'v2/lib/api/access-management';
import { ModalState, RoleManagementModal } from './RoleManagementModal';
import { useFetchRoles } from 'v2/lib/hooks/useFetchRoles';
import { GenericTabHeader } from '../components/GenericTabHeader';
import { SettingsTableContainer } from '../components/SettingsTableContainer';
const initialModalState: ModalState = {
  open: false,
  mode: 'view',
  roleId: null,
  title: '',
  editableRole: false,
};
export const RoleManagement = () => {
  const [modalState, setModalState] = useState<ModalState>(initialModalState);

  const handleClose = () => {
    setModalState(initialModalState);
  };

  const { roles, rolesPagination, changeRolesPagination, isLoadingRoles, rolesError, setRolesSearch } = useFetchRoles({
    q: undefined,
    roleIds: undefined,
    permissionSetIds: undefined,
    page: 1,
    perPage: undefined,
  });

  const permissionSetChip = (permissionSet: string) => {
    return <Chip sx={{ marginRight: '0.25rem' }} size='small' key={permissionSet} label={permissionSet} />;
  };

  const rows = roles.map((role) => ({
    role: role.name,
    type: role.type,
    description: role.description || 'No description provided',
    permission_sets: role.permissionSets?.map((permissionSet) => permissionSetChip(permissionSet.name)),
    id: role.id,
  }));

  const columns: Array<GridColDef> = [
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      type: 'string',
      minWidth: 150,
      flex: 2,
    },

    {
      field: 'permission_sets',
      headerName: 'Permission Sets',
      renderCell: (params) => params.value,
      minWidth: 150,
      flex: 3,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 96,
      cellClassName: 'actions',
      align: 'center',
      type: 'actions',
      getActions: ({ id, row }: GridRowParams) => {
        return [
          <GridActionsCellItem
            key='edit'
            icon={<VisibilityIcon />}
            label='View'
            aria-label='view role'
            className='textPrimary'
            onClick={() => {
              setModalState({
                open: true,
                mode: 'view',
                roleId: Number(id),
                title: 'View Role',
                editableRole: row.type === 'custom',
              });
            }}
            color='default'
          />,
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            disabled={row.type !== 'custom'}
            aria-label='edit role'
            className='textPrimary'
            onClick={() => {
              setModalState({ open: true, mode: 'edit', roleId: Number(id), title: 'Edit Role' });
            }}
            color='default'
          />,
        ];
      },
    },
  ];

  const handleFilterChange = (newFilter: Partial<RequestSearchRoles>) => {
    setRolesSearch((prevFilter) => ({ ...prevFilter, ...newFilter }));
  };

  const dataGridPaginationModel = {
    pageSize: rolesPagination.perPage ?? PerPageEnum.NUMBER_5,
    page: (rolesPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeRolesPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  return (
    <Box pt={2.5}>
      <GenericTabHeader
        title='Role Management'
        subtitle='Manage the roles and permissions of users within your organisation.'
        buttonText='New Role'
        buttonIcon={<AddIcon />}
        onButtonClick={() => {
          setModalState({ open: true, mode: 'edit', roleId: null, title: 'Create role' });
        }}
      />
      <SettingsTableContainer>
        <RoleManagementTableFilters onFilterChange={handleFilterChange} />
        {rolesError && <Alert severity='error'>An error occurred while fetching Roles</Alert>}
        <DataGrid
          isRowSelectable={() => false}
          disableColumnFilter
          disableColumnSorting
          autoHeight
          loading={isLoadingRoles}
          paginationMode={'server'}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowCount={rolesPagination?.total || 0}
          paginationModel={dataGridPaginationModel}
          onPaginationModelChange={handlePaginationUpdate}
          columns={columns}
          rows={rows}
        />
        {modalState.open && (
          <RoleManagementModal modalState={modalState} handleClose={handleClose} handleModalState={setModalState} />
        )}
      </SettingsTableContainer>
    </Box>
  );
};
