import React, { ReactNode } from 'react';
import { Box, Typography, Button } from '@zitcha/component-library';

interface HeaderProps {
  title: string;
  subtitle: string;
  buttonText?: string;
  buttonIcon?: ReactNode;
  onButtonClick?: () => void;
}

export const GenericTabHeader: React.FC<HeaderProps> = ({ title, subtitle, buttonText, buttonIcon, onButtonClick }) => {
  return (
    <Box display='flex' gap={2} flexDirection='column'>
      <Typography variant='h5'>{title}</Typography>
      <Box display='flex' justifyContent='space-between' gap={2}>
        <Typography variant='subtitle1'>{subtitle}</Typography>
        {buttonText && buttonIcon && onButtonClick && (
          <Button variant='contained' color='primary' startIcon={buttonIcon} onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};
