import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  p {
    font-size: 1.2em;
  }
`;

const AppLoading = () => {
  return (
    <StyledLoaderWrapper>
      <p>The page is loading, please wait.</p>
      <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
    </StyledLoaderWrapper>
  );
};

export default AppLoading;
