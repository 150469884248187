import React from 'react';
import { useDispatch } from 'react-redux';
import { clearSession } from '../../../features/session/sessionSlice';
import { Navigate } from 'react-router-dom';
import { auth0Enabled } from '../../../envVars';
import { useAuth0 } from '@auth0/auth0-react';
import AppLoading from '../../components/AppLoading';

const Logout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  localStorage.removeItem('auth0Enabled');

  dispatch(clearSession());

  if (auth0Enabled()) {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    return <AppLoading />;
  }

  return <Navigate to='/login' replace />;
};
export default Logout;
